import { GroupsActionTypes } from "./groups.types";

const INITIAL_STATE = {
  groupsLoading: false,
  groups: null,
  groupsErrorMessage: undefined,
  students: null,
  studentsLiveStatus: {},
  studentsLoading: false,
  studentsErrorMessage: undefined,
  currentGroup: null,
  reports: null,
  reportsLoading: false,
  reportsErrorMessage: undefined,
  lastRefreshed: null
};

const groupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GroupsActionTypes.RESET_GROUPS:
      return INITIAL_STATE;
    case GroupsActionTypes.FETCH_GROUPS_START:
      return {
        ...state,
        groupsLoading: true,
      };
    case GroupsActionTypes.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        groupsLoading: false,
        groupsErrorMessage: undefined,
      };
    case GroupsActionTypes.FETCH_GROUPS_FAILURE:
      return {
        ...state,
        groupsErrorMessage: action.payload,
        groupsLoading: false,
      };
    case GroupsActionTypes.FETCH_STUDENTS_START:
      return {
        ...state,
        studentsLoading: true,
      };
    case GroupsActionTypes.FETCH_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload,
        studentsLoading: false,
        studentsErrorMessage: undefined,
      };
    case GroupsActionTypes.FETCH_STUDENTS_FAILURE:
      return {
        ...state,
        studentsErrorMessage: action.payload,
        studentsLoading: false,
      };
    case GroupsActionTypes.SET_STUDENTS_LIVE_STATUS:
      return {
        ...state,
        studentsLiveStatus: action.payload,
      };
    case GroupsActionTypes.UPDATE_STUDENT_LIVE_STATUS:
      return {
        ...state,
        studentsLiveStatus: {
          ...state.studentsLiveStatus,
          [action.payload.id]: action.payload,
        },
      };
    case GroupsActionTypes.SET_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: action.payload,
      };
    case GroupsActionTypes.SET_LAST_REFRESHED:
        return {
          ...state,
          lastRefreshed: action.payload,
        };
    case GroupsActionTypes.FETCH_REPORTS_START:
      return {
        ...state,
        reportsLoading: true,
        reportsErrorMessage: undefined,
      };
    case GroupsActionTypes.FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        reportsLoading: false,
        reports: action.payload,
      };
    case GroupsActionTypes.FETCH_REPORTS_FAILURE:
      return {
        ...state,
        reportsLoading: false,
        reportsErrorMessage: action.payload,
      };
    case GroupsActionTypes.REFRESH_GROUP_START:
      return {
        ...state,
        studentsLoading: true,
      };
    case GroupsActionTypes.REFRESH_GROUP_SUCCESS:
      return {
        ...state,
        currentGroup: action.payload,
      };
    case GroupsActionTypes.REFRESH_GROUP_FAILURE:
      return {
        ...state,
        studentsLoading: false,
        groupsErrorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default groupsReducer;
