// Moduels
import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";

// Components
import CustomDropdown from "../../../material-kit-components/CustomDropdown/CustomDropdown";
import Button from "../../../material-kit-components/CustomButtons/Button.js";
import { Box } from "@material-ui/core";

const DataRenderedVideo = ({
  item,
  challengeNumber,
  urlExtensionForAssets,
  autoPlayVideo,
  isExplicitVideoUrl,
}) => {
  const [videoPlaybackSpeed, setVideoPlaybackSpeed] = useState(1);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(null);

  const player = useRef();
  let videoUrl;

  if (isExplicitVideoUrl) {
    videoUrl = item;
  } else {
    const stringSplit = item.split(":");
    const videoName =
      challengeNumber +
      "-" +
      stringSplit
        .filter((item, i) => i >= 1)
        .join(":")
        .trim();
    videoUrl = urlExtensionForAssets + videoName;
  }

  const isM3U8 = videoUrl.endsWith(".m3u8");

  const handleSpeedChange = (e) => {
    const speed = Number(e.target.innerText.split("X")[0]);
    setVideoPlaybackSpeed(speed);
  };

  const handleBitrateChange = (index) => {
    console.log(index);
    const internalPlayer = player.current?.getInternalPlayer("hls");
    if (internalPlayer) {
      // currentLevel expect to receive an index of the levels array
      internalPlayer.currentLevel = index;
    }
  };

  const handlePlayerReady = () => {
    setIsPlayerReady(true);
    // const internalPlayer = player.current?.getInternalPlayer("hls");
    // if (internalPlayer) {
    //   setCurrentLevel(internalPlayer.currentLevel);
    // }
  };

  useEffect(() => {
    const internalPlayer = player.current?.getInternalPlayer("hls");
    const handleLevelSwitched = (event, data) => {
      setCurrentLevel(data.level);
    };
    if (internalPlayer && isPlayerReady) {
      internalPlayer.on(window.Hls.Events.LEVEL_SWITCHED, handleLevelSwitched);
    }
    return () => {
      if (internalPlayer) {
        internalPlayer.off(
          window.Hls.Events.LEVEL_SWITCHED,
          handleLevelSwitched
        );
      }
    };
  }, [isPlayerReady]);

  return (
    <div>
      <ReactPlayer
        width={"100%"}
        height={isM3U8 ? "50vh" : "100%"}
        controls
        url={videoUrl}
        playbackRate={videoPlaybackSpeed}
        playing={autoPlayVideo}
        ref={player}
        onReady={handlePlayerReady}
      />
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <CustomDropdown
          dropup
          dropdownHeader="Speed"
          buttonText="Playback Speed"
          buttonProps={{
            round: true,
            color: "primary",
            size: "sm",
          }}
          noLiPadding
          dropdownList={[
            <Button
              simple
              color="transparent"
              style={{
                padding: "10px",
                margin: "0",
                width: "100%",
                height: "100%",
                justifyContent: "start",
              }}
              onClick={handleSpeedChange}
              key="1.0"
            >
              1.0x
            </Button>,
            <Button
              simple
              color="transparent"
              style={{
                padding: "10px",
                margin: "0",
                width: "100%",
                height: "100%",
                justifyContent: "start",
              }}
              onClick={handleSpeedChange}
              key="1.25"
            >
              1.25x
            </Button>,
            <Button
              simple
              color="transparent"
              style={{
                padding: "10px",
                margin: "0",
                width: "100%",
                height: "100%",
                justifyContent: "start",
              }}
              onClick={handleSpeedChange}
              key="1.5"
            >
              1.5x
            </Button>,
            <Button
              simple
              color="transparent"
              style={{
                padding: "10px",
                margin: "0",
                width: "100%",
                height: "100%",
                justifyContent: "start",
              }}
              onClick={handleSpeedChange}
              key="1.75"
            >
              1.75x
            </Button>,
            <Button
              simple
              color="transparent"
              style={{
                padding: "10px",
                margin: "0",
                width: "100%",
                height: "100%",
                justifyContent: "start",
              }}
              onClick={handleSpeedChange}
              key="2.0"
            >
              2.0x
            </Button>,
          ]}
        />
        {isM3U8 && isPlayerReady && (
          <CustomDropdown
            dropup
            dropdownHeader="Quality"
            buttonText="Quality"
            buttonProps={{
              round: true,
              color: "primary",
              size: "sm",
            }}
            noLiPadding
            dropdownList={player.current
              ?.getInternalPlayer("hls")
              ?.levels.map((level, index) => (
                <Button
                  simple
                  color="transparent"
                  style={{
                    padding: "10px",
                    margin: "0",
                    width: "100%",
                    height: "100%",
                    justifyContent: "start",
                    backgroundColor:
                      currentLevel === index ? "#d3d3d3" : "transparent",
                  }}
                  onClick={() => handleBitrateChange(index)}
                  key={index}
                >
                  {level.height}p
                </Button>
              ))}
          />
        )}
      </Box>
    </div>
  );
};

export default DataRenderedVideo;
