// Modules
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// Material UI
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

// Components
import Card from "../../material-kit-pro-components/Card/Card.js";
import CardBody from "../../material-kit-pro-components/Card/CardBody.js";
import GridContainer from "../../material-kit-components/Grid/GridContainer";
import GridItem from "../../material-kit-components/Grid/GridItem";
import ChallengeDataRenderer from "../ChallengeDataRenderer/ChallengeDataRenderer.component.jsx";
import Primary from "../../material-kit-components/Typography/Primary.js";
import RegularButton from "../../material-kit-pro-components/CustomButtons/Button.js";

// Assets and styling
import WellDoneRocket from "../../assets/icons/WellDoneRocket/WellDoneRocket.icon.jsx";

// Code split for tutor functionality
const ChallengePaginator = React.lazy(() =>
  import("./Components/ChallengePaginator.component")
);

const LessonWellDoneCard = ({
  challenge,
  setInstructionsChecked,
  completedChallengesArr,
  setCompletedChallengesArr,
  currentIndex,
  setCurrentIndex,
  challenges,
  adminMode,
  lessonId,
  handleNextClick,
}) => {
  const theme = useTheme();

  const lgBreakpointMediaQuery = useMediaQuery(theme.breakpoints.up("lg"));

  const { example, explanation, hints, instructions } = challenge;
  const allDataArr = [...example, ...explanation, ...hints, ...instructions];

  return (
    <div>
      <Helmet>
        <title>
          {challenge.challengeNumber}-{challenge.title}
        </title>
      </Helmet>
      <Card
        style={{ margin: "1.5rem 0 0 0", minHeight: "calc(100vh - 12rem)" }}
      >
        <CardBody
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {adminMode && (
            <Suspense fallback={<div></div>}>
              <ChallengePaginator
                {...{ currentIndex, setCurrentIndex, challenges }}
              />
            </Suspense>
          )}
          <GridContainer
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              flex: "1",
              padding: "0 1rem",
            }}
            spacing={5}
          >
            <GridItem
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: lgBreakpointMediaQuery ? "start" : "center",
              }}
            >
              <Box display="flex" style={{ gap: "1rem" }}>
                <WellDoneRocket />
                <Primary>
                  <h1>
                    <strong>{challenge.title}</strong>
                  </h1>
                </Primary>
              </Box>
              <Primary>
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "35px",
                    textAlign: lgBreakpointMediaQuery ? "left" : "center",
                  }}
                >
                  {challenge.subtitle}
                </h2>
              </Primary>
              {challenge.challengeType === "well_done_1" && (
                <RegularButton
                  onClick={handleNextClick}
                  style={{ padding: "8px 12px", marginTop: "2rem" }}
                  color="primary"
                >
                  Start Level 2
                  <ChevronRight style={{ margin: 0, marginLeft: "4px" }} />
                </RegularButton>
              )}
              {challenge.challengeType === "well_done_2" && (
                <Link to="/">
                  <RegularButton
                    onClick={handleNextClick}
                    style={{ padding: "8px 12px", marginTop: "2rem" }}
                    color="primary"
                  >
                    <ChevronLeft />
                    Back to Lessons
                  </RegularButton>
                </Link>
              )}
            </GridItem>
            <GridItem lg={6}>
              <ChallengeDataRenderer
                checkbox
                listArray={allDataArr}
                challengeNumber={challenge.challengeNumber}
                challengeId={challenge.id}
                lessonIdProp={lessonId}
                {...{
                  setInstructionsChecked,
                  setCompletedChallengesArr,
                  completedChallengesArr,
                }}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default LessonWellDoneCard;
