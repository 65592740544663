import React from "react";
import Card from "../../material-kit-pro-components/Card/Card";
import CardBody from "../../material-kit-pro-components/Card/CardBody";
import ErrorMessage from "../ErrorMessage/ErrorMessage.component";

const ErrorCard = ({ children, onRetry }) => {
  return (
    <Card>
      <CardBody>
        <ErrorMessage onRetry={onRetry}>{children}</ErrorMessage>
      </CardBody>
    </Card>
  );
};

export default ErrorCard;
