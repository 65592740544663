import React, { useState } from "react";
import { ArrowForwardIos } from "@material-ui/icons";
import Button from "material-kit-components/CustomButtons/Button";
import { makeStyles } from "@material-ui/styles";
import { Popover } from "@material-ui/core";
import popoverStyles from "assets/jss/material-kit-react/popoverStyles.js";
import { previousNextButtonStyles } from "./PreviousNextButton.styles";

const styles = {
  ...popoverStyles,
  previousNextButtonStyles,
};
const useStyles = makeStyles(styles);

const NextButton = ({
  lastChallenge,
  nextButtonActive,

  handleNextClick,
}) => {
  const [anchorElTop, setAnchorElTop] = useState(null);

  const classes = useStyles();
  return (
    <div className={classes.previousNextButtonStyles}>
      <Button
        disabledHelper={!nextButtonActive}
        disabled={lastChallenge}
        justIcon
        round
        color="primary"
        onClick={
          nextButtonActive
            ? handleNextClick
            : (e) => setAnchorElTop(e.currentTarget)
        }
      >
        <ArrowForwardIos />
      </Button>

      <Popover
        classes={{
          paper: classes.popover,
        }}
        open={Boolean(anchorElTop)}
        anchorEl={anchorElTop}
        onClose={() => setAnchorElTop(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <h3 className={classes.popoverHeader}>
          Have you checked your instructions?
        </h3>
        <div className={classes.popoverBody}>
          You will only be able to move on if you have ticked off all your
          instructions.
        </div>
      </Popover>
    </div>
  );
};

export default NextButton;
