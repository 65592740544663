import { LessonsActionTypes } from "./lessons.types";

const INITIAL_STATE = {
  lessonsLoading: false,
  thisWeeksLessons: [],
  previousLessons: [],
  allLessons: {},
  currentLesson: {},
  errorMessage: undefined,
  trackCheckpointSelection: {
    track: null,
    checkpoint: null,
  },
};

const lessonsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LessonsActionTypes.SET_THIS_WEEKS_LESSONS:
      return {
        ...state,
        thisWeeksLessons: Array.from(
          new Set([...state.thisWeeksLessons, action.payload.id])
        ),
        allLessons: {
          ...state.allLessons,
          [action.payload.id]: action.payload,
        },
      };
    case LessonsActionTypes.ADD_LESSON_TO_STORE:
      return {
        ...state,
        previousLessons: Array.from(
          new Set([...state.previousLessons, action.payload.id])
        ),
        allLessons: {
          ...state.allLessons,
          [action.payload.id]: action.payload,
        },
      };
    case LessonsActionTypes.RESET_LESSONS:
      return { ...INITIAL_STATE, currentLesson: state.currentLesson };

    case LessonsActionTypes.SET_TRACK_CHECKPOINT_SELECTION:
      return {
        ...state,
        trackCheckpointSelection: action.payload,
      };

    case LessonsActionTypes.FETCH_LESSONS_START:
      return {
        ...state,
        lessonsLoading: true,
      };
    case LessonsActionTypes.FETCH_LESSONS_SUCCESS:
      return {
        ...state,
        thisWeeksLessons: Object.keys(action.payload[0]),
        previousLessons: Object.keys(action.payload[1]),
        allLessons: {
          ...state.allLessons,
          ...action.payload[0],
          ...action.payload[1],
        },
        lessonsLoading: false,
      };
    case LessonsActionTypes.FETCH_LESSONS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        lessonsLoading: false,
      };

    case LessonsActionTypes.FETCH_CURRENT_LESSON_START:
      return {
        ...state,
        lessonsLoading: true,
      };
    case LessonsActionTypes.FETCH_CURRENT_LESSON_SUCCESS:
      return {
        ...state,
        currentLesson: { ...action.payload },
        lessonsLoading: false,
      };
    case LessonsActionTypes.FETCH_CURRENT_LESSON_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        lessonsLoading: false,
      };
    default:
      return state;
  }
};

export default lessonsReducer;
