// Modules
import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Redux actions and selectors
import {
  selectStudentMode,
  selectCurrentUser,
} from "../../redux/user/user.selectors";

// Hooks
import useLessonUpdateMangement from "../../customHooks/useLessonUpdateMangement.hook";

//Material
import { makeStyles, useTheme, useMediaQuery, Box } from "@material-ui/core";

// Components
import LessonChallengeCard from "../../components/LessonChallengeCard/LessonChallengeCard.component.jsx";
import PreviousButton from "../../components/PreviousNextButtons/PreviousButton.component";
import NextButton from "../../components/PreviousNextButtons/NextButton.component";
import LessonWellDoneCard from "../../components/LessonChallengeCard/LessonWellDoneCard.component";
import Header from "../../material-kit-components/Header/Header";
import SubHeaderBackToLessons from "../../material-kit-components/Header/SubHeaderBackToLessons";

// Assets and styling
import { cardTitle } from "../../assets/jss/material-kit-react.js";

// Code splitting tutor functionality
const SubHeaderLinks = React.lazy(() =>
  import("../../material-kit-components/Header/SubHeaderLinks")
);

const styles = {
  cardTitle,
  challengeContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: 0,
    margin: 0,
  },
  fixedButtons: {
    position: "fixed",
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px 0 16px",
    top: "40vh",
    zIndex: "100",
  },
  inlineButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "20px",
  },
};

const useStyles = makeStyles(styles);

const LessonPage = ({ currentUser, studentMode }) => {
  const {
    lessonId,
    lesson,
    currentIndex,
    handlePrevClick,
    challenge,
    challenges,
    nextButtonActive,
    handleNextClick,
    setInstructionsChecked,
    completedChallengesArr,
    setCompletedChallengesArr,
    setCurrentIndex,
  } = useLessonUpdateMangement();

  // Styles
  const classes = useStyles();
  const theme = useTheme();
  const xsBreakpointMediaQuery = useMediaQuery(theme.breakpoints.up("sm"));

  if (!lessonId) {
    return <Redirect to="/" />;
  } else {
    return (
      <div>
        <Header
          hideHeader={xsBreakpointMediaQuery ? true : false}
          color={"primary"}
          brand={<strong>{currentUser?.displayName || "RocketHour"}</strong>}
          bottomRightLinks={
            currentUser?.admin ? (
              <Suspense fallback={<div></div>}>
                <SubHeaderLinks />
              </Suspense>
            ) : null
          }
          bottomLeftLinks={<SubHeaderBackToLessons />}
          fixed
        />
        {lesson && (
          <div
            className={classes.challengeContainer}
            style={{ flexDirection: xsBreakpointMediaQuery ? "row" : "column" }}
          >
            {/* Buttons that appear at the top on mobile but fixed to the sides on desktop */}
            <Box minWidth={xsBreakpointMediaQuery ? "5rem" : "100vw"}>
              <div
                className={
                  xsBreakpointMediaQuery
                    ? classes.fixedButtons
                    : classes.inlineButtons
                }
              >
                <PreviousButton
                  {...{
                    currentIndex,
                    handlePrevClick,
                  }}
                />

                {!challenge?.challengeType?.includes("well_done") && (
                  <NextButton
                    lastChallenge={
                      currentIndex + 1 === challenges.length ? true : false
                    }
                    {...{
                      nextButtonActive,
                      handleNextClick,
                    }}
                  />
                )}
              </div>
            </Box>
            {/* Cards to show for lesson */}
            <div style={{ zIndex: 200, padding: "5px", width: "100%" }}>
              {challenge?.challengeType?.includes("well_done") ? (
                <LessonWellDoneCard
                  adminMode={currentUser?.admin && !studentMode}
                  lessonId={lesson.lessonId}
                  {...{
                    challenge,
                    setInstructionsChecked,
                    completedChallengesArr,
                    setCompletedChallengesArr,
                    currentIndex,
                    setCurrentIndex,
                    challenges,
                    handleNextClick,
                  }}
                >
                  well dun
                </LessonWellDoneCard>
              ) : (
                <LessonChallengeCard
                  adminMode={currentUser?.admin && !studentMode}
                  lessonId={lesson.lessonId}
                  {...{
                    challenge,
                    setInstructionsChecked,
                    completedChallengesArr,
                    setCompletedChallengesArr,
                    currentIndex,
                    setCurrentIndex,
                    challenges,
                  }}
                />
              )}
            </div>

            <Box minWidth="5rem" />
          </div>
        )}
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  studentMode: selectStudentMode,
});

export default connect(mapStateToProps)(LessonPage);
