// Modules
import React, { useState } from "react";

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

// MK Components
import Primary from "../../material-kit-components/Typography/Primary";

// Custom Components
import LessonPreview from "../LessonPreview/LessonPreview.component";
import ChallengeProgressIndicator from "../ChallengeProgressIndicator/ChallengeProgressIndicator.component";

// Assets and styling
import { lessonPreviewAccordionStyle } from "./LessonPreviewAccordion.style";
import useLessonProgress from "../../customHooks/useLessonProgress.hook";

const useStyle = makeStyles(lessonPreviewAccordionStyle);

const LessonPreviewAccordion = ({
  lesson,
  handleWatchVideoClick,
  userLessonTimeValidation,
  useExpandedState,
}) => {
  const [expanded, setExpanded] = useExpandedState
    ? useExpandedState
    : useState(false);

  const progress = useLessonProgress(lesson);

  const classes = useStyle();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      classes={{ root: classes.accordionRoot }}
      expanded={expanded === lesson.id}
      onChange={handleChange(lesson.id)}
    >
      <AccordionSummary
        classes={{ expanded: classes.accordionSummaryExpanded }}
        expandIcon={<ExpandMore />}
      >
        <Primary>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "40px" }}>
              {progress > 0 && (
                <ChallengeProgressIndicator progressDecimal={progress} />
              )}
            </div>
            <h4>
              <strong>Lesson {lesson.lessonNumber}</strong>
              {expanded !== lesson.id && (
                <span>
                  <span style={{ margin: "20px" }}>|</span>
                  <span>{lesson.title}</span>
                </span>
              )}
            </h4>
          </div>
        </Primary>
      </AccordionSummary>
      <AccordionDetails>
        <LessonPreview
          {...{
            lesson,
            handleWatchVideoClick,
            userLessonTimeValidation,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default LessonPreviewAccordion;
