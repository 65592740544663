import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectUserErrorMessage = createSelector(
  [selectUser],
  (user) => user.errorMessage
);

export const selectStudentMode = createSelector(
  [selectUser],
  (user) => user.studentMode
);

export const selectTutorSchedule = createSelector(
  [selectUser],
  (user) => user.tutorSchedule
);

export const selectTutorFeedbackDashboard = createSelector(
  [selectUser],
  (user) => user.tutorFeedbackDashboard
);

export const selectUserLessonsData = createSelector([selectUser], (user) => {
  const data = user.userLessonData;
  return data;
});

export const selectUserLessonData = (lessonId) =>
  createSelector(
    [selectUserLessonsData],
    (userLessonsData) => userLessonsData[lessonId]
  );
