export const GroupsActionTypes = {
  RESET_GROUPS: "RESET_GROUPS",
  FETCH_GROUPS_START: "FETCH_GROUPS_START",
  FETCH_GROUPS_SUCCESS: "FETCH_GROUPS_SUCCESS",
  FETCH_GROUPS_FAILURE: "FETCH_GROUPS_FAILURE",
  FETCH_STUDENTS_START: "FETCH_STUDENTS_START",
  FETCH_STUDENTS_SUCCESS: "FETCH_STUDENTS_SUCCESS",
  FETCH_STUDENTS_FAILURE: "FETCH_STUDENTS_FAILURE",
  SET_CURRENT_GROUP: "SET_CURRENT_GROUP",
  SET_LAST_REFRESHED: "SET_LAST_REFRESHED",
  SET_STUDENTS_LIVE_STATUS: "SET_STUDENTS_LIVE_STATUS",
  UPDATE_STUDENT_LIVE_STATUS: "UPDATE_STUDENT_LIVE_STATUS",
  FETCH_REPORTS_START: "FETCH_REPORTS_START",
  FETCH_REPORTS_SUCCESS: "FETCH_REPORTS_SUCCESS",
  FETCH_REPORTS_FAILURE: "FETCH_REPORTS_FAILURE",
  REFRESH_GROUP_START: "REFRESH_GROUP_START",
  REFRESH_GROUP_SUCCESS: "REFRESH_GROUP_SUCCESS",
  REFRESH_GROUP_FAILURE: "REFRESH_GROUP_FAILURE",
};
