import { Box } from "@material-ui/core";
import React from "react";
import RegularButton from "../../material-kit-components/CustomButtons/Button";
import Primary from "../../material-kit-components/Typography/Primary";

const ErrorMessage = ({ onRetry, children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Primary>
        <h4>
          <strong>There seems to be a problem!</strong>
        </h4>
      </Primary>
      <p>{children}</p>
      {onRetry && (
        <RegularButton color="warning" onClick={onRetry}>
          Retry
        </RegularButton>
      )}
    </Box>
  );
};

export default ErrorMessage;
