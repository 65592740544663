// Modules
import React, { Suspense, useMemo } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// redux actions and selectors
import { selectPreviousLessons } from "../../redux/lessons/lessons.selectors.js";
import {
  selectCurrentUser,
  selectStudentMode,
} from "../../redux/user/user.selectors.js";

// Material UI
import { Box } from "@material-ui/core";
import { MenuBook, Movie } from "@material-ui/icons";

// Components
import Button from "../../material-kit-pro-components/CustomButtons/Button.js";
import CardBody from "../../material-kit-pro-components/Card/CardBody.js";
import Muted from "../../material-kit-components/Typography/Muted.js";
import StartButtonCombined from "./Components/StartButtonCombined.component.jsx";

//Assets and styling
import Primary from "../../material-kit-components/Typography/Primary.js";

// Code Split tutor functionality
const AdminTrainingNotes = React.lazy(() =>
  import("./Components/AdminTrainingNotes.component.jsx")
);

// @ts-ignore

const LessonPreview = ({
  currentUser,
  studentMode,
  previousLessons,
  lesson,
  handleWatchVideoClick,
  userLessonTimeValidation,
}) => {
  // Memoised validation value and availabilities of clickable buttons
  const lessonValidated = useMemo(() => {
    if (previousLessons.includes(lesson.id) || userLessonTimeValidation) {
      return true;
    } else {
      return false;
    }
  }, [previousLessons, lesson, userLessonTimeValidation]);

  const lessonAvailable = useMemo(() => {
    return lesson?.challenges?.find((challenge) =>
      challenge.challengeType.includes("level")
    )
      ? true
      : false;
  }, [lesson]);

  const livestreamAvailable = useMemo(() => {
    return lesson?.challenges?.find((challenge) =>
      challenge.challengeType.includes("livestream")
    )
      ? true
      : false;
  }, [lesson]);

  const tutorGuideAvailable = useMemo(() => {
    return lesson?.challenges?.find((challenge) =>
      challenge.challengeType.includes("tutor_guide")
    )
      ? true
      : false;
  }, [lesson]);

  return (
    <CardBody>
      <Box height="100%">
        <Box width="100%" display="flex" justifyContent={"space-between"}>
          <Primary>
            <h4 style={{ marginTop: "18px", marginBottom: "18px" }}>
              <strong>{lesson?.title}</strong>
            </h4>
          </Primary>
          {lessonValidated ? (
            <Box
              display="flex"
              // justifyContent="flex-start"
              flexWrap="wrap"
              alignItems="center"
            >
              {lessonAvailable && <StartButtonCombined {...{ lesson }} />}
              {livestreamAvailable && (
                <Button
                  size="sm"
                  onClick={() => handleWatchVideoClick(lesson, "livestream")}
                  color="danger"
                  style={{ marginRight: "20px" }}
                >
                  <Movie /> Livestream
                </Button>
              )}
              {tutorGuideAvailable && currentUser?.admin && !studentMode && (
                <Button
                  size="sm"
                  onClick={() => handleWatchVideoClick(lesson, "tutor_guide")}
                  color="info"
                >
                  <MenuBook /> Tutor Guide
                </Button>
              )}
            </Box>
          ) : null}
        </Box>
        <Box>
          <h5 style={{ marginTop: 0, paddingTop: 0 }}>Lesson Overview</h5>
          {currentUser?.admin && !studentMode && lesson?.trainingNotes ? (
            <Suspense fallback={<div></div>}>
              <AdminTrainingNotes {...{ lesson }} />
            </Suspense>
          ) : (
            <p>{lesson?.lessonOverview || ""}</p>
          )}
        </Box>
        {!lessonValidated && (
          <Box>
            <Muted>_________________________</Muted>
            <Muted>Lesson Not Yet Available</Muted>
          </Box>
        )}
      </Box>
    </CardBody>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  previousLessons: selectPreviousLessons,
  studentMode: selectStudentMode,
});

export default connect(mapStateToProps)(LessonPreview);
