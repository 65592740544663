import React from "react";

// MUI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Slide from "@material-ui/core/Slide";
import { Box, Divider } from "@material-ui/core";

// Assets and styling
import modalStyle from "../../../assets/jss/material-kit-react/modalStyle.js";
import ChallengeDataRenderer from "../../ChallengeDataRenderer/ChallengeDataRenderer.component.jsx";
import Primary from "../../../material-kit-components/Typography/Primary.js";

const style = {
  ...modalStyle,
  //   hints: { display: "flex", justifyContent: "center" },
};
const useStyle = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const HintsModal = ({ showHints, setShowHints, challenge, lessonId }) => {
  const classes = useStyle();

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={showHints}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setShowHints(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle id="classic-modal-slide-title" disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Primary>
            <h4 className={`${classes.modalTitle}`}>
              <strong>Hint</strong>
            </h4>
          </Primary>
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setShowHints(false)}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        <ChallengeDataRenderer
          listArray={challenge.hints}
          challengeNumber={challenge.challengeNumber}
          challengeId={challenge.id}
          lessonIdProp={lessonId}
        />
      </DialogContent>
    </Dialog>
  );
};

export default HintsModal;
