// Modules
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import HelpIcon from "@material-ui/icons/Help";
import Button from "../../../material-kit-pro-components/CustomButtons/Button.js";
import { handleHints } from "utils/helpers.js";

const DataRenderedText = ({ item, classes, setInTextHints }) => {
  const { textWithoutHints, hintContentArray } = handleHints(item);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "15px",
        marginLeft: "0px",
        justifyContent: "space-between",
      }}
      className={`${classes.label} ${classes.nonCheckBox} ${classes.reactMarkdown}`}
    >
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {textWithoutHints}
      </ReactMarkdown>
      {hintContentArray.length > 0 ? (
        <Button
          onClick={() =>
            setInTextHints({ show: true, hintArray: hintContentArray })
          }
          color="hint"
          size="sm"
          style={{ padding: "8px 16px", margin: 0 }}
        >
          <HelpIcon style={{ fontSize: 50 }} />
          Hint
        </Button>
      ) : null}
    </div>
  );
};

export default DataRenderedText;
