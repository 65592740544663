import { listAirtableRecords } from "../firebase/airtable-api-calls.utils";
import { groupsTableFieldNames } from "./ids-and-fieldnames/airtable-groups-table-fieldnames.utils";
import { mainbaseTableIds } from "./ids-and-fieldnames/airtable-mainbase-table-ids.utils";
import { reportTemplateChecklistsFieldNames } from "./ids-and-fieldnames/airtable-report-template-checklists-fieldnames.utils";
import { reportsTableFieldnames } from "./ids-and-fieldnames/airtable-reports-table-fieldnames.utils";
import { studentsTableFieldnames } from "./ids-and-fieldnames/airtable-students-table-fieldnames.utils";
import { tutorBillingTableFieldNames } from "./ids-and-fieldnames/airtable-billing-table-field-ids.utils";
import {
  tutorsTableFieldnames,
  groupTutorFieldNames,
} from "./ids-and-fieldnames/airtable-tutors-table-fieldnames.utils";

/**
All the functions below call our listAirtableRecords firebase function 
(a serverless google functions API that requires a call to come from an admin user)
The function simply athenticates the call and then passes our query via the airtable API
Using the "listRecords" end point. This function expects an object with the tableName (or ID)
that you are making the call to and a select object which can take any values that the .select()
method can take in the airtable javascript sdk. 
Read more in the airtable API docs https://airtable.com/appRnmsfuJPOiO1IF/api/docs#javascript
 */

export const getTutorRecordByID = (id) => {
  // All the fields we want returned from the API, keeping it to a minimum, referencing fields
  // only by the fieldnames object in case of changes
  const requiredTutorFields = Object.values(tutorsTableFieldnames);

  // Building a select object that checks if airtable record id matches our user id
  const tutorSelectObject = {
    fields: requiredTutorFields,
    filterByFormula: `IF(RECORD_ID() = "${id}", TRUE())`,
  };

  // Grab the matching tutor record with fields containing linked groups
  return listAirtableRecords({
    tableName: mainbaseTableIds.tutors,
    selectObject: tutorSelectObject,
  });
};

// Takes response.data from getTutorRecordByID
export const getGroupRecordsLinkedToTutor = (tutorRecord) => {
  const primaryTutorGroupIds =
    tutorRecord[0][tutorsTableFieldnames.primary_tutor_groups] || [];
  const secondaryTutorGroupIds =
    tutorRecord[0][tutorsTableFieldnames.secondary_tutor_groups] || [];
  const tertiaryTutorGroupIds =
    tutorRecord[0][tutorsTableFieldnames.tertiary_tutor_groups] || [];

  // Build an array combining fields which could contain a linked group
  let groupIds = [
    ...primaryTutorGroupIds,
    ...secondaryTutorGroupIds,
    ...tertiaryTutorGroupIds,
  ];

  // Make array unique
  groupIds = Array.from(new Set(groupIds));

  // Create a formula to filter out the matching groups
  const recordIDSwitchLines = groupIds.map((groupId) => `"${groupId}", TRUE()`);

  const switchFormula = `
                  SWITCH(
                    RECORD_ID(),
                    ${recordIDSwitchLines.join(",")},
                    FALSE()
                  )
                `;
  // All the fields we want returned from the API, keeping it to a minimum, referencing fields
  // only by the fieldnames object in case of changes
  const requiredFields = Object.values(groupsTableFieldNames);
  const selectObject = {
    fields: requiredFields,
    filterByFormula: switchFormula,
  };

  return listAirtableRecords({
    tableName: mainbaseTableIds.groups,
    selectObject,
  }).then((fetchedGroupsObject) => {
    let groupRecords = [];

    fetchedGroupsObject.data.forEach((group) => {
      groupRecords.push(group);
    });

    let billedGroupPromises = groupRecords.map((groupRecord) => {
      //Check if billing group
      if (
        groupRecord?.[groupsTableFieldNames.group_type_gr] === "class" ||
        groupRecord?.[groupsTableFieldNames.group_type_gr] === "booster" ||
        groupRecord?.[groupsTableFieldNames.group_type_gr] === "starter"
      ) {
        //Fetch billing information for group tutors
        return buildBilledGroup(groupRecord);

        // overwrite group data with new billedGroup data
      } else {
        return Promise.resolve(groupRecord);
      }
    });

    return Promise.all(billedGroupPromises).catch((err) => {
      throw "An Error occured fetching billing groups: " + err;
    });
  });
};

export const buildBilledGroup = async (groupsRecord) => {
  let group = { ...groupsRecord };

  const allGroupTutorIds = [
    ...(group?.[groupsTableFieldNames.primary_tutor_link] ?? []),
    ...(group?.[groupsTableFieldNames.secondary_tutor_link] ?? []),
  ];
  const uniqueGroupTutorIds = Array.from(new Set(allGroupTutorIds));
  const recordIdSwitchLines = uniqueGroupTutorIds.map(
    (tutorId) => `"${tutorId}", TRUE()`
  );
  const filterFormula = `
  SWITCH(
    RECORD_ID(), 
    ${recordIdSwitchLines.join(",")},
    FALSE()
  )`;

  const tutorBillingSelectObject = {
    fields: Object.values(groupTutorFieldNames),
    filterByFormula: filterFormula,
  };

  const tutorBillingQuery = await listAirtableRecords({
    tableName: mainbaseTableIds.tutors,
    selectObject: tutorBillingSelectObject,
  });

  const tutorBilling = [];
  tutorBillingQuery.data.forEach((tutorRecord) => {
    let tutor = { ...tutorRecord };

    if (group?.[groupsTableFieldNames.primary_tutor_link].includes(tutor.id)) {
      tutor[tutorBillingTableFieldNames.tutor_role] = "primary";
    } else if (
      group?.[groupsTableFieldNames.secondary_tutor_link].includes(tutor.id)
    ) {
      tutor[tutorBillingTableFieldNames.tutor_role] = "secondary";
    } else {
      return;
    }
    tutorBilling.push(tutor);
  });

  return { ...group, tutorBilling };
};

export const getGroupRecordByID = (groupRecordID) => {
  const requiredFields = Object.values(groupsTableFieldNames);
  const selectObject = {
    fields: requiredFields,
    filterByFormula: `IF(RECORD_ID() = "${groupRecordID}", TRUE())`,
  };

  const atr = listAirtableRecords({
    tableName: mainbaseTableIds.groups,
    selectObject,
  });
  return atr;
};

export const fetchStudentsByIDs = (studentIDArr) => {
  // Set up a switch statement to be passed in as an airtable filtering formulat
  //   The statement will look at each record ID in the table an return true if a record
  // matches one of the IDs in the studentIDArr
  // Get unique elements, studentIDArr contains both current and usual students
  const studentIDSet = Array.from(new Set(studentIDArr));
  const recordIDSwitchLines = (studentIDSet || []).map(
    (studentId) => `"${studentId}", TRUE()`
  );
  const switchFormula = `
		SWITCH(
		  RECORD_ID(),
		  ${recordIDSwitchLines.join(",")},
		  FALSE()
		)
	  `;
  // All the fields we want returned from the API, keeping it to a minimum, referencing fields
  // only by the fieldnames object in case of changes
  const requiredFields = Object.values(studentsTableFieldnames);
  //   Pass in the select object with required fields and filter by formula
  const selectObject = {
    fields: requiredFields,
    filterByFormula: switchFormula,
  };
  return listAirtableRecords({
    tableName: mainbaseTableIds.students,
    selectObject,
  });
};

// This is to be passed a reporting period id and an array of student IDs.
// We cannot simply pass in a group ID to get all the relevant students
// as it is possible for students to shift classes without this changing on their
// report.
export const getReportsForGroup = async (reportingPeriodID, studentIDArr) => {
  if (!reportingPeriodID || !studentIDArr?.length) {
    throw new Error(
      "There was a problem getting reports. Check that you have assigned a reporting period correctly and that there are students in the group"
    );
  }
  // Get unique elements, studentIDArr contains both current and usual students
  const studentIDSet = Array.from(new Set(studentIDArr));

  const requiredFields = Object.values(reportsTableFieldnames);

  // Set up a switch statement to be passed in as an airtable filtering formulat
  //  The statement will look at each record ID in the table an return true if a record
  // matches one of the IDs in the studentIDArr
  const recordIDSwitchLines = (studentIDSet || []).map(
    (studentId) => `"${studentId}", TRUE()`
  );
  const switchFormula = `
		SWITCH(
		  {${reportsTableFieldnames.student_record_id_st}},
		  ${recordIDSwitchLines.join(",")},
		  FALSE()
		)
	  `;

  // Combine the swithFormula with looking up the correct reportingPeriod ID
  // with an AND statement
  const formula = `
  AND(
    ${switchFormula},
    {${reportsTableFieldnames.reporting_period_record_id_rp}} = "${reportingPeriodID}"
    )
  `;

  return listAirtableRecords({
    tableName: mainbaseTableIds.reports,
    selectObject: { fields: requiredFields, filterByFormula: formula },
  });
};

// fetches each of the checklist items linked to a report template using the report template recod ID
// Sorts them in order of checklist_order_number
export const getReportChecklist = async (reportTemplateID) => {
  if (!reportTemplateID) return;

  const tableName = mainbaseTableIds.report_template_checklists;

  const filterByFormula = `{${reportTemplateChecklistsFieldNames.report_template_record_id_rt}} = "${reportTemplateID}"`;
  const sort = [
    {
      field: reportTemplateChecklistsFieldNames.checklist_order_number,
      direction: "asc",
    },
  ];

  const fields = Object.values(reportTemplateChecklistsFieldNames);

  const selectObject = { filterByFormula, sort, fields };

  return listAirtableRecords({
    tableName,
    selectObject,
  });
};

// Takes an array of report records from airtable and performs a memoised fetch for each template's checklist
// Combines the checklist with the report and maps it to an object used in redux reducer
export const combineReportObjectsWithChecklists = async (reportsArr) => {
  // The final object returned
  const reportsObj = {};
  // Caching checklist values as most of the time students in one class will have the same report template
  const cachedReportChecklists = {};

  // Loops through each report and begins construction of report object
  for (const report of reportsArr) {
    // If no studentID is present continue as this report would not be valid
    const studentID = report[reportsTableFieldnames.student_record_id_st]?.[0];
    if (!studentID) {
      continue;
    }
    // Used to look up checklist and ID the cached checklist
    const reportTemplateID =
      report?.[reportsTableFieldnames.report_template]?.[0];

    // If there is a reportTemplate ID attached to the report and the checklist
    // is not already cached, perform a fetch call for the checklist and then cache it
    if (
      reportTemplateID &&
      cachedReportChecklists[reportTemplateID] === undefined
    ) {
      const reportChecklist = await getReportChecklist(reportTemplateID);
      // If there are checklist items, map them into the required format and store it in cache
      // Cache a null value if nothing is returned
      let checklist = null;
      if (reportChecklist?.data?.length) {
        checklist = reportChecklist.data.map((record) => ({
          id: record.id,
          item: record[reportTemplateChecklistsFieldNames.checklist_item],
          type: record[reportTemplateChecklistsFieldNames.type],
        }));
      }
      cachedReportChecklists[reportTemplateID] = checklist;
    }

    // Setup keys for report object
    const pastFeedbackComments =
      report[reportsTableFieldnames.past_month_tutor_comments];
    const pastReportComments =
      report[reportsTableFieldnames.past_year_report_comments];
    const checklist = cachedReportChecklists[reportTemplateID];
    const lockedReport = report[reportsTableFieldnames?.launchpad_lock];

    // Set the key on the reports obj as the student ID with the report as the value
    reportsObj[studentID] = {
      id: report.id,
      checklist,
      pastFeedbackComments,
      pastReportComments,
      lockedReport,
    };
  }

  return reportsObj;
};
