// Modules
import React, { useEffect, useRef, useState } from "react";

// Material
import { Fullscreen } from "@material-ui/icons";
import GifPlayer from "react-gif-player";

// Components
import RocketLoader from "../RocketLoader/RocketLoader.component";
import Button from "../../material-kit-components/CustomButtons/Button.js";

const GifCanvasAnimator = ({
  gifData,
  modal,
  setModal,
  loop,
  frameAnimated,
  src,
}) => {
  const [timeoutCalls, setTimeoutCalls] = useState([]);
  const [hover, setHover] = useState(false);

  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);

  useEffect(() => {
    if (gifData) {
      drawFrameToCanvas(gifData[0]);
    }

    return () => timeoutCalls.forEach((call) => clearTimeout(call));
  }, [gifData]);

  const drawFrameToCanvas = (frame) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const image = frame.getImage();
    const ctx = canvas.getContext("2d");
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0, image.width, image.height);
  };

  //Function will either trigger gif animation or it will clear animation timeout intervals and reset gif.
  const handleGifToggle = (action = "start") => {
    const node = canvasContainerRef.current;
    if (!node || !gifData) {
      return;
    }
    if (timeoutCalls.length || action === "stop") {
      //change styling to show the gif play button
      node.classList.remove("playing");
      //clear all timeout functions to draw frames to canvas
      timeoutCalls.forEach((call) => clearTimeout(call));
      //draw first frame to canvas
      drawFrameToCanvas(gifData[0]);
      //reset timeout calls
      setTimeoutCalls([]);
    } else {
      //remove gif play button
      node.classList.add("playing");

      //counters for adding time delays to frames
      let currentFrameDelay = 0;
      let cummulativeFrameDelay = 0;

      for (const [i, frame] of gifData.entries()) {
        cummulativeFrameDelay += currentFrameDelay;
        //frame delays are using 1 hundredth of a second rather than millisecond
        currentFrameDelay = frame.frameInfo.delay * 10;
        //create a timeout to draw frame to canvas
        const timeoutId = setTimeout(() => {
          drawFrameToCanvas(frame);
          //If last frame stop the gif and reset after current delay of the frame
          if (i === gifData.length - 1) {
            const lastFrameTimeoutId = setTimeout(() => {
              if (loop) {
                handleGifToggle("start");
              } else {
                handleGifToggle("stop");
              }
            }, currentFrameDelay);
            setTimeoutCalls((current) => [...current, lastFrameTimeoutId]);
          }
        }, cummulativeFrameDelay);
        //create an array of timeout calls that can be cleared.
        setTimeoutCalls((current) => [...current, timeoutId]);
      }
    }
  };

  const handleModalOpen = () => {
    setModal(true);
    if (gifData) {
      handleGifToggle("stop");
    }
  };

  return (
    <div
      className="gif_player gifContainer"
      ref={canvasContainerRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!modal && (
        <Button
          color={hover ? "warning" : null}
          simple={hover ? false : true}
          onClick={handleModalOpen}
          id="gif-modal-button"
          style={{
            position: "absolute",
            right: "0",
            padding: "4px",
            margin: "3px",
            zIndex: "10",
          }}
        >
          <Fullscreen style={{ margin: "0" }} />
        </Button>
      )}
      {frameAnimated ? (
        <div onClick={handleGifToggle}>
          {gifData ? <div className="play_button" /> : <RocketLoader />}
          <canvas style={{ width: "100%" }} ref={canvasRef} />
        </div>
      ) : (
        <GifPlayer gif={src} crossOrigin="true" />
      )}
    </div>
  );
};

export default GifCanvasAnimator;
