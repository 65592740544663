import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/functions";

const config = {
  apiKey: "AIzaSyBKWVBiclGdsTljBMjGjPpBClkH3BFaVYI",
  authDomain: "rockethour-lms.firebaseapp.com",
  projectId: "rockethour-lms",
  storageBucket: "rockethour-lms.appspot.com",
  messagingSenderId: "408788480633",
  appId: "1:408788480633:web:a6a5ada763c0f9d03c9aa8",
  databaseURL: "https://rockethour-lms-default-rtdb.firebaseio.com/",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const s3BucketUrlForChallengeAssets =
  "https://d1oodynkqkkl7e.cloudfront.net/rh_lessons/";

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const realtimeDB = firebase.database();
const cloudFunctions = firebase.functions();
// cloudFunctions.useEmulator("localhost", 5001);
export { cloudFunctions };
