// Modules
import React, { Suspense, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Redux actions and selectors
import { selectCurrentUser } from "../../redux/user/user.selectors.js";
import { setCurrentUser } from "../../redux/user/user.actions";
import { fetchCurrentLesson } from "../../redux/lessons/lessons.actions.js";

// Hooks
import useLessonUpdateMangementAdmin from "../../customHooks/useLessonUpdateMangementAdmin.hook.js";

//Material
import { makeStyles, useTheme, useMediaQuery, Box } from "@material-ui/core";

// Components
import LessonChallengeCard from "../../components/LessonChallengeCard/LessonChallengeCard.component.jsx";
import PreviousButton from "../../components/PreviousNextButtons/PreviousButton.component.jsx";
import NextButton from "../../components/PreviousNextButtons/NextButton.component.jsx";
import LessonWellDoneCard from "../../components/LessonChallengeCard/LessonWellDoneCard.component.jsx";
import Header from "../../material-kit-components/Header/Header.js";
import SubHeaderBackToLessons from "../../material-kit-components/Header/SubHeaderBackToLessons.js";

// Assets and styling
import { cardTitle } from "../../assets/jss/material-kit-react.js";
import RocketLoader from "components/RocketLoader/RocketLoader.component.jsx";
import { auth } from "utils/firebase/firebase-utils.js";
import { getUserRef } from "../../utils/firebase/firestore-utils";

// Code splitting tutor functionality
const SubHeaderLinks = React.lazy(() =>
  import("../../material-kit-components/Header/SubHeaderLinks.js")
);

const styles = {
  cardTitle,
  challengeContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: 0,
    margin: 0,
  },
  fixedButtons: {
    position: "fixed",
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px 0 16px",
    top: "40vh",
    zIndex: "100",
  },
  inlineButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "20px",
  },
};

const useStyles = makeStyles(styles);

const LessonPageAdmin = ({
  fetchCurrentLesson,
  currentUser,
  setCurrentUser,
}) => {
  const {
    lessonId,
    lesson,
    currentIndex,
    handlePrevClick,
    challenge,
    challenges,
    nextButtonActive,
    handleNextClick,
    setInstructionsChecked,
    completedChallengesArr,
    setCompletedChallengesArr,
    setCurrentIndex,
  } = useLessonUpdateMangementAdmin(fetchCurrentLesson);
  const classes = useStyles();
  const theme = useTheme();
  const xsBreakpointMediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged((userAuth) => {
      const userRef = getUserRef(userAuth);
      userRef.onSnapshot(
        (snapShot) => {
          if (snapShot.exists) {
            const document = snapShot.data();
            setCurrentUser({
              id: snapShot.id,
              admin: document.userType?.includes("admin") ? true : false,
              ...document,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    });
    return () => {
      unsubscribeFromAuth();
    };
  }, []);
  if (!lessonId) {
    return <Redirect to="/" />;
  } else {
    return (
      <div>
        <Header
          hideHeader={xsBreakpointMediaQuery ? true : false}
          color={"primary"}
          brand={<strong>{"RocketHour"}</strong>}
          bottomRightLinks={
            <Suspense fallback={<div></div>}>
              <SubHeaderLinks />
            </Suspense>
          }
          bottomLeftLinks={<SubHeaderBackToLessons />}
          fixed
        />
        {lesson ? (
          <div
            className={classes.challengeContainer}
            style={{ flexDirection: xsBreakpointMediaQuery ? "row" : "column" }}
          >
            {/* Buttons that appear at the top on mobile but fixed to the sides on desktop */}
            <Box minWidth={xsBreakpointMediaQuery ? "5rem" : "100vw"}>
              <div
                className={
                  xsBreakpointMediaQuery
                    ? classes.fixedButtons
                    : classes.inlineButtons
                }
              >
                <PreviousButton
                  {...{
                    currentIndex,
                    handlePrevClick,
                  }}
                />

                {!challenge?.challengeType?.includes("well_done") && (
                  <NextButton
                    lastChallenge={
                      currentIndex + 1 === challenges.length ? true : false
                    }
                    {...{
                      nextButtonActive,
                      handleNextClick,
                    }}
                  />
                )}
              </div>
            </Box>
            {/* Cards to show for lesson */}
            <div style={{ zIndex: 200, padding: "5px", width: "100%" }}>
              {challenge?.challengeType?.includes("well_done") ? (
                <LessonWellDoneCard
                  adminMode={currentUser?.admin}
                  lessonId={lesson.lessonId}
                  {...{
                    challenge,
                    setInstructionsChecked,
                    completedChallengesArr,
                    setCompletedChallengesArr,
                    currentIndex,
                    setCurrentIndex,
                    challenges,
                    handleNextClick,
                  }}
                >
                  well dun
                </LessonWellDoneCard>
              ) : (
                <LessonChallengeCard
                  adminMode={currentUser?.admin}
                  lessonId={lesson.lessonId}
                  {...{
                    challenge,
                    setInstructionsChecked,
                    completedChallengesArr,
                    setCompletedChallengesArr,
                    currentIndex,
                    setCurrentIndex,
                    challenges,
                  }}
                />
              )}
            </div>

            <Box minWidth="5rem" />
          </div>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"80vh"}
          >
            <RocketLoader />
          </Box>
        )}
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentLesson: (lessonId) => dispatch(fetchCurrentLesson(lessonId)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonPageAdmin);
