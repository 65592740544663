// Modules
import { createSelector } from "reselect";

const selectNewsState = (state) => state.news;

export const selectNews = createSelector(
  [selectNewsState],
  (news) => news.news
);

export const selectNewsLoading = createSelector(
  [selectNewsState],
  (news) => news.loading
);

export const selectNewsErrorMessage = createSelector(
  [selectNewsState],
  (news) => news.errorMessage
);
