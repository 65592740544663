import React, { useMemo } from "react";
import {
  infoColor,
  successColor,
} from "../../assets/jss/material-kit-pro-react";

import completeCheck from "./Check_ring_duotone.svg";

const ChallengeProgressIndicator = ({ progressDecimal }) => {
  const {
    outerStrokeWidth,
    outerRadius,
    radius,
    innerStrokeWidth,
    innerCircumference,
  } = useMemo(() => {
    const outerStrokeWidth = 8;
    const outerRadius = (100 - outerStrokeWidth) / 2;
    const radius = 25 - outerStrokeWidth - 2;
    const innerStrokeWidth = radius * 2;
    const innerCircumference = 2 * Math.PI * radius;

    return {
      outerStrokeWidth,
      outerRadius,
      radius,
      innerStrokeWidth,
      innerCircumference,
    };
  }, []);

  const color = (progressDecimal || 0) < 1 ? infoColor[3] : successColor[0];

  return (
    <div style={{ width: "24px", height: "24px" }}>
      {(progressDecimal || 0) < 1 ? (
        <svg
          height="100%"
          width="100%"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
        >
          <circle
            cx="50"
            cy="50"
            r={outerRadius}
            stroke={color}
            strokeWidth={outerStrokeWidth}
          />
          <circle
            r={radius}
            cx="50"
            cy="50"
            fill="transparent"
            stroke={color}
            strokeWidth={innerStrokeWidth}
            strokeDasharray={`calc(${
              progressDecimal || 0
            } * ${innerCircumference}) ${innerCircumference}`}
            transform="rotate(-90) translate(-100)"
          />
        </svg>
      ) : (
        <img
          src={completeCheck}
          style={{ width: "100%", height: "100%" }}
          alt="Lesson complete badge"
        />
      )}
    </div>
  );
};

export default ChallengeProgressIndicator;
