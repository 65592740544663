// Modules
import React from "react";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

//Components
import { Box, makeStyles } from "@material-ui/core";
import Primary from "../../material-kit-components/Typography/Primary";
import Muted from "../../material-kit-components/Typography/Muted";

import DataRenderedEmbed from "../ChallengeDataRenderer/components/DataRenderedEmbed.component";
import DataRenderedVideo from "../ChallengeDataRenderer/components/DataRenderedVideo.component";
import DataRenderedImage from "../ChallengeDataRenderer/components/DataRenderedImage.component";

// Selectors and Redux
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";

// Styles
import { NewsFeedStyles } from "./NewsFeedCard.styles";

const useStyles = makeStyles(NewsFeedStyles);

const NewsFeedContent = ({ currentUser, newsObject }) => {
  const classes = useStyles();

  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="start"
        flexDirection="column"
        paddingBottom="10px"
      >
        <Primary>
          <h5 style={{ margin: "0px" }}>
            <strong>{newsObject.title}</strong>
          </h5>
        </Primary>
        <Muted>
          <h5>{newsObject.date}</h5>
        </Muted>
      </Box>
      <Box>
        <ReactMarkdown remarkPlugins={[remarkGfm]} linkTarget="_blank">
          {newsObject.text}
        </ReactMarkdown>
        {newsObject.asset.type === "embed" ? (
          <DataRenderedEmbed
            item={newsObject.asset.value}
            classes={classes}
            currentUser={currentUser}
            studentMode
          />
        ) : newsObject.asset.type === "video" ? (
          <DataRenderedVideo
            item={newsObject.asset.value}
            isExplicitVideoUrl
            classes={classes}
          />
        ) : newsObject.asset.type === "image" ||
          newsObject.asset.type === "gif" ? (
          <DataRenderedImage
            item={newsObject.asset.value}
            classes={classes}
            isExplicitUrl
            explicitUrlType={newsObject.asset.type}
          />
        ) : (
          <div>There appears to be an issue with this News Item!</div>
        )}
        {/* <DataRenderedEmbed
          item={newsObject.asset.value}
          classes={classes}
          currentUser={currentUser}
          studentMode={true}
        /> */}
      </Box>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(NewsFeedContent);
