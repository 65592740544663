/* eslint-disable no-unused-vars */
// Modules
import React, {
  /* eslint-enable no-unused-vars */
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// Redux actions and selectors
import {
  selectStudentMode,
  selectUserLessonData,
  selectCurrentUser,
} from "../redux/user/user.selectors";

import { selectAllLessons } from "../redux/lessons/lessons.selectors";
import { addToLessonStore } from "../redux/lessons/lessons.actions";

// Firebase
import { setCurrentChallengeStatus } from "../utils/firebase/realtime-db-utils";
import { setUserLessonProgressDoc } from "../utils/firebase/firestore-utils";
import { firestore } from "../utils/firebase/firebase-utils";

const useLessonUpdateMangement = () => {
  const allLessons = useSelector(selectAllLessons);
  const currentUser = useSelector(selectCurrentUser);
  const studentMode = useSelector(selectStudentMode);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [instructionsChecked, setInstructionsChecked] = useState(false);
  const [nextButtonActive, setNextButtonActive] = useState(false);
  const [completedChallengesArr, setCompletedChallengesArr] = useState([]);

  // Used for navigating to the correct lesson/challenge
  const { lessonId, challengeId } = useParams();
  const history = useHistory();

  const lesson = useMemo(() => {
    const currentLesson = allLessons[lessonId] || null;

    if (!currentLesson) {
      history.push("/");
    }
    return currentLesson;
  }, [allLessons]);

  const challenges = useMemo(() => {
    const filteredChallenges = (lesson?.challenges || []).filter(
      (challenge) =>
        challenge?.challengeType !== "livestream" &&
        challenge?.challengeType !== "tutor_guide"
    );

    if (!filteredChallenges.length) {
      history.push("/");
    }
    return filteredChallenges;
  }, [lesson]);

  const challenge = useMemo(
    () => challenges[currentIndex],
    [challenges, currentIndex]
  );

  // Firebase lesson progress data for current lesson
  const lessonProgressData = useSelector(selectUserLessonData(lessonId));

  const addToCompleteChallengesArr = useCallback(
    (value) => {
      const array = Array.isArray(value) ? value : [value];
      setCompletedChallengesArr((prevArray) =>
        Array.from(new Set([...prevArray, ...array]))
      );
    },
    [setCompletedChallengesArr]
  );

  // If there are completed challenges in lessonProgressData (from firestore)
  // add them to the local completed challenges state
  useEffect(() => {
    if (
      lessonProgressData?.completedChallenges?.length &&
      !currentUser?.admin
    ) {
      addToCompleteChallengesArr(lessonProgressData.completedChallenges);
    }
  }, [lessonProgressData]);

  /*Set snapshot listener for any live updates to lesson */
  useEffect(() => {
    const unsubscribeFromLessonListener = firestore
      .doc(`lessons/${lessonId}`)
      .onSnapshot((doc) => {
        const lesson = doc.data();
        addToLessonStore(lesson);
      });

    return () => unsubscribeFromLessonListener();
  }, [lessonId]);

  //If a route is navigated to with a challenge id, set the index to match that id
  useEffect(() => {
    if (challengeId) {
      const challengeIndex = challenges.findIndex(
        (challenge) => challenge.id === challengeId
      );
      if (challengeIndex !== -1) {
        setCurrentIndex(challengeIndex);
      }
      // If in student mode, don't navigate to the most recent challenge
    } else if (lessonProgressData?.completedChallenges?.length & !studentMode) {
      const challengeIndex = challenges.findIndex(
        (challenge) =>
          !lessonProgressData.completedChallenges.includes(challenge.id)
      );
      if (challengeIndex !== -1) {
        setCurrentIndex(challengeIndex);
      }
    }
  }, [challengeId, challenges, lessonProgressData]);

  const handleChallengeCompleted = useCallback(
    (currentUser, lesson, challenge) => {
      setNextButtonActive(true);

      // Stores lesson data and completion status on firebase
      setUserLessonProgressDoc(currentUser, lesson, challenge?.id).catch(
        console.error
      );

      // Update if level challenge so as not to trigger a live update.
      addToCompleteChallengesArr(challenge.id);
    },
    []
  );
  /*Manage challenge access based on privileges or instructions checked */
  useEffect(() => {
    if (
      (challenge && (instructionsChecked || challenge?.skippable)) ||
      !challenge?.instructions?.length
    ) {
      handleChallengeCompleted(currentUser, lesson, challenge);
    } else if (currentUser?.admin && !studentMode) {
      setNextButtonActive(true);
    } else {
      setNextButtonActive(false);
    }
  }, [challenge, currentIndex, instructionsChecked, currentUser, studentMode]);

  // Memoised array of challenges containing "level" in the challengeType
  const levelChallengesNumber = useMemo(() => {
    return challenges.filter((challenge) =>
      challenge?.challengeType.includes("level")
    ).length;
  }, [challenges]);

  /* Fire off status to the realtime database for tutor tracking */
  useEffect(() => {
    if (challenge && currentUser) {
      const date = new Date();

      const lessonStatus = {
        totalChallenges: levelChallengesNumber,
        completedChallenges: challenge.challengeNumber,
        currentChallengeNumber: challenge.challengeNumber,
        currentChallengeTitle: challenge.title,
        currentLessonId: lesson.lessonId,
        currentLessonRecordId: lesson.id,
        lastChanged: date,
        presence: { online: true, lastChanged: date },
      };

      if (challenge && !challenge?.challengeType?.includes("level")) {
        delete lessonStatus.completedChallenges;
      }
      setCurrentChallengeStatus({
        currentUser,
        lessonStatus,
      });
    }
  }, [challenge]);

  // handlers
  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    setInstructionsChecked(false);
  };

  const handleNextClick = () => {
    if (currentIndex < challenges.length - 1) {
      if (currentUser?.admin && !studentMode) {
        handleChallengeCompleted(currentUser, lesson, challenge);
      }
      setCurrentIndex(currentIndex + 1);
    }
    setInstructionsChecked(false);
  };
  return {
    lessonId,
    lesson,
    currentIndex,
    handlePrevClick,
    challenge,
    challenges,
    nextButtonActive,
    handleNextClick,
    setInstructionsChecked,
    completedChallengesArr,
    setCompletedChallengesArr,
    setCurrentIndex,
  };
};

export default useLessonUpdateMangement;
