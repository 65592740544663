//Modules
import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Redux actions an selectors
import {
  selectAvailableLessonTrackAndCheckpointDropDownArr,
  selectAvailableLessonTrackArray,
  selectTrackCheckpointSelection,
} from "../../redux/lessons/lessons.selectors";
import { setTrackCheckpointSelection } from "../../redux/lessons/lessons.actions";

// Components
import CustomDropdown from "../../material-kit-pro-components/CustomDropdown/CustomDropdown";
import DashboardDropDownMultiLevelList from "./DashboardDropDownMultiLevelList.component";

const DashboardDropDown = ({
  setTrackCheckpointSelection,
  trackCheckpointSelection,
  availableLessonTrackArray,
  availableLessonTrackAndCheckpointDropDownArr,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const currentTrack = trackCheckpointSelection?.track;
  const currentCheckpoint = trackCheckpointSelection?.checkpoint;

  if (
    availableLessonTrackAndCheckpointDropDownArr.length > 10 &&
    availableLessonTrackArray.length > 1
  ) {
    return <DashboardDropDownMultiLevelList />;
  } else {
    return (
      <CustomDropdown
        buttonText={
          currentTrack && currentCheckpoint
            ? `${currentTrack} Checkpoint ${currentCheckpoint}`
            : "Select Checkpoint"
        }
        buttonProps={{
          color: "transparent",
          block: true,
        }}
        parentAnchor={[anchorEl, setAnchorEl]}
        dropPlacement="bottom"
        onClick={(item) => {
          const { track, checkpoint } = item.props;
          setTrackCheckpointSelection({
            track,
            checkpoint,
          });
          setAnchorEl(null);
        }}
        dropdownList={availableLessonTrackAndCheckpointDropDownArr.map(
          (item) => {
            if (item.header) {
              return item;
            } else {
              const { track, checkpoint } = item;
              return (
                <span track={track} checkpoint={checkpoint}>
                  Checkpoint {checkpoint}
                </span>
              );
            }
          }
        )}
      />
    );
  }
};

const mapStateToProps = createStructuredSelector({
  availableLessonTrackArray: selectAvailableLessonTrackArray,
  trackCheckpointSelection: selectTrackCheckpointSelection,
  availableLessonTrackAndCheckpointDropDownArr:
    selectAvailableLessonTrackAndCheckpointDropDownArr,
});

const mapDispatchToProps = (dispatch) => ({
  setTrackCheckpointSelection: (selectionObj) =>
    dispatch(setTrackCheckpointSelection(selectionObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDropDown);
