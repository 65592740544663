import { getUserLessonDocs } from "../../utils/firebase/firestore-utils";
import { fetchGroupsStartAsync } from "../groups/groups.actions";
import {
  fetchCurrentLessonsOnlyAsyncStart,
  fetchLessonsAsyncStart,
  resetLessons,
} from "../lessons/lessons.actions";
import { fetchNewsStartAsync } from "redux/news/news.actions";
import { selectCurrentUser } from "./user.selectors";
import { UserActionTypes } from "./user.types";

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setStudentMode = (mode) => ({
  type: UserActionTypes.SET_STUDENT_MODE,
  payload: mode,
});

export const setTutorSchedule = (bool) => ({
  type: UserActionTypes.SET_TUTOR_SCHEDULE,
  payload: bool,
});

export const setTutorFeedbackDashboard = (bool) => ({
  type: UserActionTypes.SET_TUTOR_FEEDBACK_DASHBOARD,
  payload: bool,
});

export const fetchUserLessonDataStart = () => ({
  type: UserActionTypes.FETCH_USER_LESSON_DATA_START,
});

export const fetchUserLessonDataSuccess = (lessonDataObj) => ({
  type: UserActionTypes.FETCH_USER_LESSON_DATA_SUCCESS,
  payload: lessonDataObj,
});

export const fetchUserLessonDataFailure = (errorMessage) => ({
  type: UserActionTypes.FETCH_USER_LESSON_DATA_FAILURE,
  payload: errorMessage,
});

// Gets all the data regarding user progress on lessons from firestore
// Stored in lessons collection under user profile doc
export const fetchUserLessonDataAsyncStart = (user) => (dispatch) => {
  if (!user.cohort) return;
  dispatch(fetchUserLessonDataStart());

  getUserLessonDocs(user)
    .then((res) => dispatch(fetchUserLessonDataSuccess(res)))
    .catch((err) => {
      console.error(err);
      dispatch(fetchUserLessonDataFailure(err.message));
    });
};

// Handles the logic of how to load up lessons and/or groups based on user type and
// admin priviledges
export const fetchLessonsAndGroupsAsyncStart = () => (dispatch, getState) => {
  const state = getState();
  const currentUser = selectCurrentUser(state);

  if (!currentUser) return;

  dispatch(resetLessons());

  if (currentUser?.admin) {
    dispatch(fetchGroupsStartAsync());
  } else if (currentUser && "cohort" in currentUser && !currentUser?.admin) {
    dispatch(fetchNewsStartAsync());
    // dispatch(fetchNewsStartAsync(currentUser.groups[0])); Shouldn't it be something like this?
    if (currentUser?.currentUserType?.includes("current_lessons")) {
      dispatch(fetchCurrentLessonsOnlyAsyncStart());
    } else {
      dispatch(fetchLessonsAsyncStart());
    }
  }
};
