/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */

import { useSelector } from "react-redux";
import {
  selectCurrentGroup,
  selectGroups,
  selectStudents,
  selectStudentsLoading,
} from "../redux/groups/groups.selectors";
import {
  selectAllLessons,
  selectLessonsLoading,
} from "../redux/lessons/lessons.selectors";
import { selectNewsLoading } from "../redux/news/news.selectors";
import { selectCurrentUser } from "../redux/user/user.selectors";
import { groupsTableFieldNames } from "../utils/airtable/ids-and-fieldnames/airtable-groups-table-fieldnames.utils";

const useAllLoading = () => {
  const currentUser = useSelector(selectCurrentUser);
  const currentGroup = useSelector(selectCurrentGroup);
  const lessonsLoading = useSelector(selectLessonsLoading);
  const groupsLoading = useSelector(selectStudentsLoading);
  const studentsLoading = useSelector(selectStudentsLoading);
  const allLessons = useSelector(selectAllLessons);
  const groups = useSelector(selectGroups);
  const students = useSelector(selectStudents);
  const newsLoading = useSelector(selectNewsLoading);

  if (lessonsLoading || groupsLoading || studentsLoading || newsLoading) {
    return true;
  } else if (!Object.keys(allLessons).length) {
    return true;
  } else if (currentUser?.admin && !groups) {
    return true;
  } else if (
    currentUser?.admin &&
    !students &&
    currentGroup[groupsTableFieldNames.link_to_students_table]
  ) {
    return true;
  } else {
    return false;
  }
};

export default useAllLoading;
