// Modules
import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

// Redux actions and selectors
import {
  selectCurrentLesson,
  selectLessonsErrorMessage,
} from "../../../redux/lessons/lessons.selectors.js";

// Components
import Card from "../../../material-kit-pro-components/Card/Card.js";
import CardBody from "../../../material-kit-pro-components/Card/CardBody.js";
import LessonPreview from "../../../components/LessonPreview/LessonPreview.component.jsx";
import CardHeader from "../../../material-kit-pro-components/Card/CardHeader.js";

const CurrentLessonCard = ({
  handleWatchVideoClick,
  userLessonTimeValidation,
  currentLesson,
}) => {
  return (
    <Card
      style={{
        minHeight: "40vh",
        minWidth: "200px",
        width: "100%",
      }}
    >
      <div>
        <CardHeader
          plain
          color="primary"
          style={{ padding: "10px 16px", marginTop: 0 }}
        >
          <h4>
            <strong>{`This Week's Lesson`}</strong>
            <span style={{ margin: "10px" }}>|</span>
            <span>
              {currentLesson?.track} {currentLesson?.checkpoint}
            </span>
            <span style={{ margin: "10px" }}>|</span>
            <span>Lesson {currentLesson?.lessonNumber}</span>
          </h4>
        </CardHeader>

        <CardBody>
          {currentLesson && (
            <div>
              <LessonPreview
                {...{
                  lesson: currentLesson,
                  handleWatchVideoClick,
                  userLessonTimeValidation,
                }}
              />
            </div>
          )}
        </CardBody>
      </div>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  lessonsErrorMessage: selectLessonsErrorMessage,
  currentLesson: selectCurrentLesson,
});

export default connect(mapStateToProps)(CurrentLessonCard);
