// Modules
import React, { useState } from "react";
import { connect } from "react-redux";

//Components
import { Box } from "@material-ui/core";
import Card from "../../material-kit-pro-components/Card/Card";
import CardBody from "../../material-kit-pro-components/Card/CardBody";
import CardHeader from "../../material-kit-pro-components/Card/CardHeader";
import NewsFeedContent from "./NewsFeedContent.component";
import Button from "../../material-kit-pro-components/CustomButtons/Button.js";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import MediaModal from "../MediaModal/MediaModal.component";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "redux/user/user.selectors";
import { selectCurrentGroup } from "redux/groups/groups.selectors";
import { selectNews } from "redux/news/news.selectors";

const NewsFeedCard = ({ currentNews }) => {
  const [newsFeedModalIsOpen, setNewsFeedModalIsOpen] = useState(false);

  return (
    <Card>
      <CardHeader color="info" plain>
        <h5 style={{ margin: 0 }}>
          <strong>News Feed</strong>
        </h5>
      </CardHeader>
      <CardBody>
        <NewsFeedContent newsObject={currentNews} />

        <MediaModal
          modal={newsFeedModalIsOpen}
          setModal={setNewsFeedModalIsOpen}
        >
          <NewsFeedContent newsObject={currentNews} />
        </MediaModal>

        <Box>
          <Button
            color="warning"
            onClick={() => setNewsFeedModalIsOpen(true)}
            style={{ width: "100%" }}
          >
            <OpenInNewIcon /> Expand
          </Button>
        </Box>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentGroup: selectCurrentGroup,
  currentNews: selectNews,
});

export default connect(mapStateToProps)(NewsFeedCard);
