// Modules
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Redirect, useLocation, useHistory } from "react-router-dom";

// Redux actions and selectors
import { selectCurrentUser } from "../../redux/user/user.selectors";

// Firebase
import { auth } from "../../utils/firebase/firebase-utils";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import { Box, Tooltip } from "@material-ui/core";

// core components
import RocketLoader from "../../components/RocketLoader/RocketLoader.component";
import Footer from "../../material-kit-components/Footer/Footer.js";
import Button from "../../material-kit-components/CustomButtons/Button.js";
import Card from "../../material-kit-pro-components/Card/Card.js";
import CardBody from "../../material-kit-pro-components/Card/CardBody.js";
import CardFooter from "../../material-kit-pro-components/Card/CardFooter.js";
import CustomInput from "../../material-kit-components/CustomInput/CustomInput.js";
import Danger from "../../material-kit-components/Typography/Danger";

// Assets and styles
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";
import { loginPageStyle } from "./LoginPage.style";
import Primary from "../../material-kit-components/Typography/Primary";
import Header from "../../material-kit-components/Header/Header";

const style = { ...styles, ...loginPageStyle };
const useStyles = makeStyles(style);

const LoginPage = ({ currentUser }) => {
  const [loginMessage, setLoginMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();
  const { search, pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    setTimeout(function () {
      setCardAnimation("");
    }, 200);
  }, []);

  useEffect(() => {
    if (pathname === "/login") {
      auth.signOut();
    }

    const query = new URLSearchParams(search);
    const emailParams = query.get("email");
    const passwordParams = query.get("password");

    if (emailParams && passwordParams) {
      setUserCredentials({
        email: emailParams,
        password: passwordParams,
      });
      history.replace({ search: "" });
    } else {
      history.replace({ search: "" });
    }

    // return () => history.replace({ search: "" });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = userCredentials;

    try {
      await auth.signInWithEmailAndPassword(email, password);
      setLoading(false);
      setUserCredentials({ email: "", password: "" });
    } catch (error) {
      setLoading(false);
      if (
        error?.code === "auth/user-not-found" ||
        error?.code === "auth/wrong-password"
      ) {
        setLoginMessage("Invalid Login Credentials");
        console.error("Invalid Login Credentials");
      } else {
        setLoginMessage(error.message || "There was a problem");
        console.error(error.message);
      }
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  if (currentUser) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className={classes.loginContainer}>
        <Header
          logo
          color={"transparent"}
          brand={<strong>{currentUser?.displayName || ""}</strong>}
          fixed
        />

        <div className={classes.container}>
          <Card className={`${classes[cardAnimaton]} ${classes.loginCard}`}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <CardBody>
                <Primary>
                  <h3>
                    <strong>Login</strong>
                  </h3>
                </Primary>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    name: "email",
                    value: userCredentials.email,
                    type: "email",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="pass"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    name: "password",
                    value: userCredentials.password,
                    type: showPassword ? "" : "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          id="tooltip-left"
                          title={`${showPassword ? "Hide" : "Show"} Password`}
                          placement="right"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button
                            simple
                            style={{ margin: "0", padding: "0" }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </Button>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Box display="flex" flexDirection="column" minHeight="80px">
                  {loading && <RocketLoader size="sm" />}
                  {loginMessage && !loading && <Danger>{loginMessage}</Danger>}
                  <Button type="submit" simple color="primary" size="lg">
                    Sign In
                  </Button>
                </Box>
              </CardFooter>
            </form>
          </Card>
          <Footer />
        </div>
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(LoginPage);
