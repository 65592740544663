import rocketBG from "../../assets/img/Login_bg.svg";

export const loginPageStyle = {
  "@global": {
    body: {
      backgroundImage: "url(" + rocketBG + ")" + " !important",
      backgroundSize: "cover",
      backgroundPosition: "center right",
      backgroundRepeat: "no-repeat",
    },
  },
  loginContainer: {
    minHeight: "100vh",
    minWidth: "100%",
    padding: 0,
    margin: 0,
  },
};
