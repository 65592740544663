export const validateLessonTime = (user) => {
  const { lessonDay, lessonTime, userType } = user;
  const daysOfTheWeekObj = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  if (
    !lessonDay ||
    !lessonTime ||
    !(lessonDay.toLowerCase() in daysOfTheWeekObj) ||
    userType !== "student"
  ) {
    console.log("passes validation 1");
    return true;
  }

  let date = new Date();
  const timezoneOffset = date.getTimezoneOffset();

  if (timezoneOffset !== -120) {
    const diff = -120 - timezoneOffset;
    date = new Date(date.getTime() - diff * 60000);
  }
  const day = date.getDay();
  const hours = date.getHours();

  const givenDayStr = lessonDay;
  const givenTimeStr = lessonTime;

  const givenDay = daysOfTheWeekObj[givenDayStr.toLowerCase()];
  if (day > givenDay && day < 5) {
    //Validation successful if day is after the planned lesson day and before Friday
    console.log("passes validation 2");
    return true;
  } else if (day > givenDay && day === 5 && hours < 18) {
    //Validation successful if day is after the planned lesson day, falls on a Friday and it is before 12:00
    console.log("passes validation 3");
    return true;
  } else if (givenDay === day) {
    //Check against given lesson time if it is currently the lesson day
    const timeStrSplit = givenTimeStr.split(":");
    const givenHours = Number(timeStrSplit[0]);
    const givenMinutes = Number(timeStrSplit[1]);

    //Construct a date object for today at the given lesson time
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentMonthDate = date.getDate();

    const lessonDateTime = new Date(
      currentYear,
      currentMonth,
      currentMonthDate,
      givenHours,
      givenMinutes
    );

    //30 Minutes converted into miliseconds
    const allocatedInterval = 30 * 60 * 1000;

    if (lessonDateTime - date < allocatedInterval) {
      //Validation succesful if within the allocatedInterval of the interval (5min currently)
      console.log("passes validation 4");
      return true;
    } else {
      //Validation fails if within the allocatedInterval of the interval (5min currently)
      console.log("fails validation 4");
      return false;
    }
  } else {
    //All other days of the week fail validation
    console.log("fails validation 5");
    return false;
  }
};
