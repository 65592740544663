// Modules
import { createSelector } from "reselect";
import update from "immutability-helper";

// Utils
import { groupsTableFieldNames } from "../../utils/airtable/ids-and-fieldnames/airtable-groups-table-fieldnames.utils";
import { studentsTableFieldnames } from "../../utils/airtable/ids-and-fieldnames/airtable-students-table-fieldnames.utils";

const selectGroupsState = (state) => state.groups;

export const selectGroups = createSelector(
  [selectGroupsState],
  (groups) => groups.groups
);
export const selectGroupsLoading = createSelector(
  [selectGroupsState],
  (groups) => groups.groupsLoading
);

export const selectGroupsErrorMessage = createSelector(
  [selectGroupsState],
  (groups) => groups.groupsErrorMessage
);

export const selectStudents = createSelector(
  [selectGroupsState],
  (groups) => groups.students
);

export const selectStudentsLiveStatusObj = createSelector(
  [selectGroupsState],
  (groups) => groups.studentsLiveStatus
);

export const selectStudentLiveStatus = (studentId) =>
  createSelector([selectStudentsLiveStatusObj], (studentsLiveStatusObj) => {
    return studentsLiveStatusObj?.[studentId] || null;
  });

export const selectStudentsLoading = createSelector(
  [selectGroupsState],
  (groups) => groups.studentsLoading
);

export const selectStudentsErrorMessage = createSelector(
  [selectGroupsState],
  (groups) => groups.studentsErrorMessage
);

export const selectReports = createSelector(
  [selectGroupsState],
  (groups) => groups.reports
);

export const selectReportsIDArr = createSelector([selectReports], (reports) =>
  reports ? Object.values(reports).map((report) => report.id) : []
);

export const selectSubmissionReportsArr = createSelector(
  [selectReports],
  (reports) =>
    reports
      ? Object.values(reports)
          .filter((report) => (report?.lockedReport ? false : report.id))
          .map((report) => report.id)
      : []
);

export const selectCurrentGroup = createSelector(
  [selectGroupsState],
  (groups) => groups.currentGroup
);

export const selectLastRefreshed = createSelector(
  [selectGroupsState],
  (groups) => groups.lastRefreshed
);

export const selectGroupsIdNameMap = createSelector(
  [selectGroups],
  (groups) => {
    return (groups || []).map((group) => ({
      id: group.id,
      name: group[groupsTableFieldNames.group_id_gr],
    }));
  }
);

export const selectClasses = createSelector([selectGroups], (groups) => {
  return (groups || []).filter((group) => {
    const groupType = group?.[groupsTableFieldNames.group_type_gr];
    return groupType !== "training" && groupType !== "library";
  });
});
export const selectLibraryGroups = createSelector([selectGroups], (groups) =>
  (groups || []).filter((group) => {
    const groupType = group?.[groupsTableFieldNames.group_type_gr];
    return groupType === "library";
  })
);
export const selectTrainingGroups = createSelector([selectGroups], (groups) =>
  (groups || []).filter((group) => {
    const groupType = group?.[groupsTableFieldNames.group_type_gr];
    return groupType === "training";
  })
);

const sorter = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

// Restructures groups into array of arrays where the top level items represents each day
// and contains an array of each group with the same day
export const selectDaysScheduleObj = (groupsSelector) =>
  createSelector([groupsSelector], (groups) => {
    if (groups) {
      let daysObject = {};

      for (let group of groups) {
        const day = group[groupsTableFieldNames.lesson_day_gr];
        daysObject = update(daysObject, {
          [day]: (lessonDay) => update(lessonDay || [], { $push: [group] }),
        });
      }

      for (let key in daysObject) {
        const unsortedGroups = daysObject[key];
        const sortedGroups = unsortedGroups.sort((a, b) => {
          const time1 = a[groupsTableFieldNames.lesson_time_gr];
          const time2 = b[groupsTableFieldNames.lesson_time_gr];

          return time1 < time2 ? -1 : 1;
        });

        daysObject[key] = sortedGroups;
      }

      return daysObject;
    } else {
      return null;
    }
  });

export const selectDaysScheduleArray = (groupsSelector) =>
  createSelector([selectDaysScheduleObj(groupsSelector)], (daysScheduleObj) => {
    if (daysScheduleObj) {
      const daysArr = Object.keys(daysScheduleObj).sort((a, b) => {
        let day1 = a.toLowerCase();
        let day2 = b.toLowerCase();
        if (!(day2 in sorter)) {
          return -1;
        }
        return sorter[day1] - sorter[day2];
      });
      return daysArr;
    } else {
      return [];
    }
  });

const getStartOfWeekAnd4Weeks = () => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  // Day-of-the-week integer of current day. 0-6, starting Sunday.
  const day = d.getDay();
  // Day-of-the-month integer of Monday for the current week.
  const mondayCurrentWeek = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  const first = new Date(d);
  first.setDate(mondayCurrentWeek - 7);
  const last = new Date(d);
  last.setDate(mondayCurrentWeek + 14);
  return { first, last };
};

export const selectStudentsUpcomingBirthdays = createSelector(
  [selectStudents],
  (students) => {
    const { first, last } = getStartOfWeekAnd4Weeks();
    return (students || [])
      .filter((student) => {
        const birthday = Date.parse(
          student?.[studentsTableFieldnames.birthday_current_year_date_st]
        );

        if (!isNaN(birthday) && birthday >= first && birthday <= last) {
          return true;
        } else {
          return false;
        }
      })
      .sort((a, b) => {
        const aBday = Date.parse(
          a?.[studentsTableFieldnames.birthday_current_year_date_st]
        );
        const bBday = Date.parse(
          b?.[studentsTableFieldnames.birthday_current_year_date_st]
        );

        return aBday - bBday;
      });
  }
);

export const selectGroupType = createSelector(
  [selectCurrentGroup],
  (currentGroup) => {
    return currentGroup[groupsTableFieldNames.group_type_gr];
  }
);
