export const LessonsActionTypes = {
  ADD_LESSON_TO_STORE: "ADD_LESSON_TO_STORE",
  SET_THIS_WEEKS_LESSONS: "SET_THIS_WEEKS_LESSONS",
  RESET_LESSONS: "RESET_LESSONS",
  SET_TRACK_CHECKPOINT_SELECTION: "SET_TRACK_CHECKPOINT_SELECTION",
  FETCH_LESSONS_START: "FETCH_LESSONS_START",
  FETCH_LESSONS_SUCCESS: "FETCH_LESSONS_SUCCESS",
  FETCH_LESSONS_FAILURE: "FETCH_LESSONS_FAILURE",
  FETCH_CURRENT_LESSON_START: "FETCH_LESSONS_START",
  FETCH_CURRENT_LESSON_SUCCESS: "FETCH_CURRENT_LESSON_SUCCESS",
  FETCH_CURRENT_LESSON_FAILURE: "FETCH_CURRENT_LESSON_FAILURE",
};
