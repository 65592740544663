export function handleHints(item) {
  let hintContent = "";
  let hintContentArray = [];
  let tempMultiContent = "";
  let insideNestedMulti = false;

  const textWithoutHints = item
    .replace(/<hint>(.*?)<\/hint>/gs, (_, content) => {
      hintContent += content;
      return "";
    })
    .replace("text:", "")
    .trim();

  let hintContentBaseArray = hintContent.includes("\n")
    ? hintContent.split("\n")
    : hintContent.length > 0
    ? [hintContent]
    : [];
  for (let element of hintContentBaseArray) {
    if (element === "<nmulti>" || element === "</nmulti>") {
      if (tempMultiContent !== "") {
        hintContentArray.push(tempMultiContent);
      }
      tempMultiContent = "";
      insideNestedMulti = !insideNestedMulti;
      continue;
    }

    if (insideNestedMulti) {
      tempMultiContent += element + "\n";
    } else {
      hintContentArray.push(element);
    }
  }
  return { textWithoutHints, hintContentArray };
}
