export const studentsTableFieldnames = {
  lms_notes_for_tutor_st: "lms_notes_for_tutor_st[SYS]",
  tutor_student_notes_previous_week_fb:
    "tutor_student_notes_previous week_fb[SYS]",
  student_status_weekly_st: "student_status_weekly_st[SYS]",
  username: "username[SYS]",
  gender_st: "gender_st[SYS]",
  age_calculated_from_birthdate_st: "age_calculated_from_birthdate_st[SYS]",
  lastname: "lastname[SYS]",
  preferred_name_st: "preferred_name_st[SYS]",
  birthday_current_year_date_st: "birthday_current_year_date_st[SYS]",
  group_id_current_gr: "groupid_current_gr",
  group_id_usual_gr: "group_id_usual_gr",
  group_id_current_string_gr: "group_id_current_string_gr[SYS]",
  group_id_usual_string_gr: "group_id_usual_string_gr",
  tutor_first_name_usual_gr_tu: "tutor_first_name_usual_gr_tu",
  current_tutor_name_gr: "current_tutor_name_gr",
};
