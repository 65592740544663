// Modules
import { React, useState } from "react";

// Components
import GifCanvasAnimatorContainer from "../../GifCanvasAnimator/GifCanvasAnimatorContainer.component";
import { Fade } from "@material-ui/core";

const DataRenderedImage = ({
  item,
  challengeNumber,
  urlExtensionForAssets,
  classes,
  isExplicitUrl,
  explicitUrlType,
}) => {
  const [loaded, setLoaded] = useState(false);
  let imageUrl, imageType;

  if (isExplicitUrl) {
    imageUrl = item;
    imageType = explicitUrlType;
  } else {
    // Example: image: Example-1.gif
    const stringSplit = item.split(":");
    // Example: ["image", "Example-1.gif"]
    const imageName =
      challengeNumber +
      // example: 10
      "-" +
      stringSplit
        .filter((item, i) => i >= 1)
        // example: [" Example-1.gif"]
        .join(":")
        // example: " Example-1.gif"
        .trim();
    // example: "Example-1.gif"
    // example: "10-Example-1.gif"

    imageUrl = urlExtensionForAssets + imageName;
    // example: "https://rockethour.s3.af-south-1.amazonaws.com/rh_lessons/i1/2.01/10-Example-1.gif"
    if (imageName.includes(".gif")) {
      imageType = "gif";
    } else {
      imageType = "image";
    }
  }

  if (imageType === "gif") {
    return (
      <div className={classes.assetContainer}>
        <GifCanvasAnimatorContainer
          className={classes.asset}
          src={imageUrl}
          loop={item.toLowerCase().includes("loopimage:")}
          frameAnimated={!item.toLowerCase().includes("longimage:")}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.assetContainer}>
        <Fade in={loaded}>
          <img
            className={classes.imageAsset}
            // style={loaded ? {} : { display: "none" }}
            src={imageUrl}
            onLoad={() => setLoaded(true)}
          />
        </Fade>
      </div>
    );
  }
};

export default DataRenderedImage;
