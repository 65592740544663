import { Box } from "@material-ui/core";
import React from "react";
import Primary from "../../material-kit-components/Typography/Primary";
import RocketLoader from "../RocketLoader/RocketLoader.component";

const LoadingScreen = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height="60vh"
      width={"100%"}
    >
      <Box>
        <Primary>
          <h3>Loading...</h3>
        </Primary>
        <RocketLoader />
      </Box>
    </Box>
  );
};

export default LoadingScreen;
