export const dashboardStyles = (theme) => ({
  dashboardContainer: {
    width: "100%",
    margin: 0,
    padding: "0 5rem",
    gap: "0 5rem",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 1rem",
    },
  },
});
