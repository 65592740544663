// Modules
import React from "react";

// Material
import { GetApp, OpenInNew } from "@material-ui/icons";

// Components
import Button from "../../../material-kit-components/CustomButtons/Button.js";

const DataRenderedButton = ({
  item,
  urlExtensionForAssets,
  challengeNumber,
}) => {
  // example: button: Go to Google: https://google.com
  const stringSplit = item.split(":");
  // example: ["button", "Go to Google",
  const buttonText = stringSplit[1];
  // example: "Go to Google"

  // This grabs the url
  const buttonContents = stringSplit
    .filter((item, i) => i >= 2)
    //example: ["https", "//google.com"]]
    .join(":")
    //example: ["https://google.com"]]
    .trim();
  // remove whitespace or any sapces

  if (item.toLowerCase().includes("downloadbutton:")) {
    const downloadUrl =
      urlExtensionForAssets + challengeNumber + "-" + buttonContents;
    return (
      <a href={downloadUrl}>
        <Button color="warning" round>
          <GetApp />
          {buttonText}
        </Button>
      </a>
    );
  } else {
    return (
      <a
        href={buttonContents}
        rel="noreferrer"
        target="_blank"
        style={{ color: "inherit" }}
      >
        <Button color="warning" style={{ width: "100%" }}>
          <OpenInNew />
          {buttonText}
        </Button>
      </a>
    );
  }
};

export default DataRenderedButton;
