// Modules
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

// MUI
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { primaryColor } from "./assets/jss/material-kit-pro-react";

// Assets and styling
import doodleBackground from "./assets/img/Doodle.svg";
import { globalStyles } from "./App.style";
import LessonPageAdmin from "views/LessonPageAdmin/LessonPageAdmin.view.jsx";
import NormalOperationView from "views/NormalOperation/NormalOperation.view";

const defaultTheme = createMuiTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: { main: primaryColor[0] },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: "url(" + doodleBackground + ")",
          ...globalStyles,
        },
      },
    },
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Helmet>
        <title>RocketHour LaunchPad</title>
      </Helmet>
      <div>
        <Switch>
          <Route path="/lessons/dev/:lessonId/:challengeId?">
            <LessonPageAdmin />
          </Route>
          <Route path="/">
            <NormalOperationView></NormalOperationView>
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
