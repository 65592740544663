// Modules
import React from "react";

// Material
import { OpenInNew } from "@material-ui/icons";

// Components
import Button from "../../../material-kit-components/CustomButtons/Button.js";

const DataRenderedEmbed = ({ item, classes, currentUser, studentMode }) => {
  let embedUrl = item.replace("embed:", "").trim();

  if (
    embedUrl.includes("scratch.mit.edu/projects") &&
    !embedUrl.includes("/embed")
  ) {
    embedUrl += "embed";
  }

  return (
    <div>
      <div className={classes.iframeContainer}>
        <iframe
          src={`${embedUrl}`}
          allowtransparency="true"
          className={classes.responsiveIframe}
          frameBorder="0"
          scrolling="no"
          style={{
            background: `url(${
              require("assets/img/rockethour-loader102.svg").default
            }) no-repeat`,
            backgroundPosition: "50% 50%",
            backgroundSize: "50px",
          }}
          allowFullScreen
        ></iframe>
      </div>
      {currentUser?.admin &&
        !studentMode &&
        embedUrl.includes("scratch.mit.edu/projects") && (
          <a
            href={embedUrl.replace("embed", "editor")}
            rel="noreferrer"
            target="_blank"
            style={{ color: "inherit" }}
          >
            <Button color="warning" style={{ width: "100%" }}>
              <OpenInNew />
              Open Scratch Editor
            </Button>
          </a>
        )}
    </div>
  );
};

export default DataRenderedEmbed;
