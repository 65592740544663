import { ArrowBackIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Button from "material-kit-components/CustomButtons/Button";
import React from "react";

import { previousNextButtonStyles } from "./PreviousNextButton.styles";

const styles = { previousNextButtonStyles };
const useStyles = makeStyles(styles);

const PreviousButton = ({ currentIndex, handlePrevClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.previousNextButtonStyles}>
      <Button
        justIcon
        round
        color="primary"
        onClick={handlePrevClick}
        disabled={currentIndex === 0}
        style={{ visibility: currentIndex === 0 ? "hidden" : "visible" }}
      >
        <ArrowBackIos />
      </Button>
    </div>
  );
};

export default PreviousButton;
