import { createSelector } from "reselect";
import update from "immutability-helper";

const selectLessons = (state) => state.lessons;

export const selectThisWeeksLessons = createSelector(
  [selectLessons],
  (lesson) => lesson.thisWeeksLessons
);

export const selectPreviousLessons = createSelector(
  [selectLessons],
  (lesson) => {
    const data = lesson.previousLessons;
    return data;
  }
);

export const selectSingleLesson = createSelector(
  [selectLessons],
  (lesson) => lesson.currentLesson
);

export const selectAllLessons = createSelector(
  [selectLessons],
  (lesson) => lesson.allLessons
);

export const selectLessonsLoading = createSelector(
  [selectLessons],
  (lesson) => lesson.lessonsLoading
);
export const selectLessonsErrorMessage = createSelector(
  [selectLessons],
  (lesson) => lesson.errorMessage
);

export const selectTrackCheckpointSelection = createSelector(
  [selectLessons],
  (lesson) => lesson.trackCheckpointSelection
);

export const selectThisWeeksLessonPresent = createSelector(
  [selectThisWeeksLessons, selectPreviousLessons],
  (thisWeeksLessons, previousLessons) => {
    const bool =
      thisWeeksLessons.length &&
      thisWeeksLessons.some((id) => !previousLessons.includes(id))
        ? true
        : false;
    return bool;
  }
);

export const selectAllLessonsObj = createSelector([selectLessons], (lesson) => {
  // Using immutablity helper to sort lessons into an object that can convert into tabbed layout
  let tempLessonsObj = {};

  /*
Sorting happens by the digit characters in lesson number and checkpoint number
These are zero padded so that they are all 2 digit strings
The original checkpoint number string is added onto the end in case there are letters after the number
in which case this will provide ordering for those letters.
*/
  const allLessonsArr = Object.values(lesson.allLessons).sort((a, b) => {
    const aCheckpointStr = (a.checkpoint.match(/\d+/) || [])
      .join("")
      .padStart(2, "0");
    const aLessonNumberStr = (a.lessonNumber.match(/\d+/) || [])
      .join("")
      .padStart(2, "0");
    const bCheckpointStr = (b.checkpoint.match(/\d+/) || [])
      .join("")
      .padStart(2, "0");
    const bLessonNumberStr = (b.lessonNumber.match(/\d+/) || [])
      .join("")
      .padStart(2, "0");
    return aCheckpointStr + aLessonNumberStr + a.checkpoint <
      bCheckpointStr + bLessonNumberStr + b.checkpoint
      ? -1
      : 1;
  });
  // Immutability helper syntax - see documentation
  allLessonsArr?.forEach((lessonObj) => {
    tempLessonsObj = update(tempLessonsObj, {
      [lessonObj.track]: (track) =>
        update(track || {}, {
          [lessonObj.checkpoint]: (checkpoint) =>
            update(checkpoint || [], { $push: [lessonObj] }),
        }),
    });
  });
  return tempLessonsObj;
});

export const selectMultipleTracksOrCheckpointsPresent = createSelector(
  [selectAllLessonsObj],
  (allLessonObj) => {
    const tracksArr = Object.keys(allLessonObj);

    if (tracksArr.length > 1) {
      return true;
    } else if (tracksArr.length === 0) {
      return false;
    } else {
      if (Object.keys(tracksArr[0]).length > 1) {
        return true;
      } else {
        return false;
      }
    }
  }
);

export const selectAvailableLessonTrackArray = createSelector(
  [selectAllLessonsObj],
  (allLessonsObj) => Object.keys(allLessonsObj).sort()
);

export const selectAvailableLessonTrackAndCheckpointDropDownArr =
  createSelector(
    [selectAllLessonsObj, selectAvailableLessonTrackArray],
    (allLessonObj, availableTracks) => {
      let dropDownArr = [];

      for (let track of availableTracks) {
        dropDownArr.push({ header: track });
        const checkpoints = Object.keys(allLessonObj[track]).map(
          (checkpoint) => ({ track, checkpoint })
        );

        dropDownArr = dropDownArr.concat(checkpoints);
      }

      return dropDownArr;
    }
  );

export const selectDefaultTrackAndCheckpoint = createSelector(
  [selectAvailableLessonTrackArray, selectAllLessonsObj],
  (availableLessonsTrackArray, allLessonsObj) => {
    const track = availableLessonsTrackArray[0] || null;
    let checkpoint = null;

    if (track) {
      const checkpointArr = Object.keys(allLessonsObj[track]);
      checkpoint = checkpointArr[checkpointArr.length - 1];
    }
    return { track, checkpoint };
  }
);

export const selectPreviousLessonsArr = createSelector(
  [selectAllLessonsObj, selectTrackCheckpointSelection],
  (allLessonsObj, trackCheckpointSelection) =>
    allLessonsObj?.[trackCheckpointSelection?.track]?.[
      trackCheckpointSelection?.checkpoint
    ] || []
);

export const selectCurrentLesson = createSelector(
  [selectThisWeeksLessons, selectAllLessons],
  (thisWeeksLessons, allLessons) => {
    if (thisWeeksLessons.length) {
      const thisWeeksLessonId = thisWeeksLessons[0];
      const currentLesson = allLessons[thisWeeksLessonId];
      return currentLesson;
    } else {
      return null;
    }
  }
);

export const selectThisWeeksLessonLength = createSelector(
  [selectThisWeeksLessons, selectAllLessons],
  (thisWeeksLessons, allLessons) => {
    const thisWeekLessonId = thisWeeksLessons[0] ?? 0;
    console.log(thisWeekLessonId);
    const lesson = allLessons?.[thisWeekLessonId];
    const lessonChallenges = lesson?.challenges ?? [];
    const codingChallenges = lessonChallenges.filter((chal) => {
      if (
        chal?.challengeType === "level_1" ||
        chal?.challengeType === "level_2"
      ) {
        return chal;
      }
    });
    return codingChallenges.length;
  }
);
