// Modules
import React from "react";

// Material UI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

// Components
import Button from "../../material-kit-components/CustomButtons/Button.js";

// Assets and styling
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="down" ref={ref} {...props} />;
});

const MediaModal = ({ modal, setModal, children }) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={modal ? true : false}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setModal(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
      maxWidth="md"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setModal(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        {children}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => setModal(false)} color="danger" round size="sm">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MediaModal;
