//Modules
import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Redux actions an selectors
import {
  selectAllLessonsObj,
  selectAvailableLessonTrackArray,
  selectTrackCheckpointSelection,
} from "../../redux/lessons/lessons.selectors";
import { setTrackCheckpointSelection } from "../../redux/lessons/lessons.actions";

// Components
import CustomDropdown from "../../material-kit-pro-components/CustomDropdown/CustomDropdown";

// Styles and assets
import { useState } from "react";

const DashboardDropDownMultiLevelList = ({
  allLessonsObj,
  setTrackCheckpointSelection,
  trackCheckpointSelection,
  availableLessonTrackArray,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const currentTrack = trackCheckpointSelection?.track;
  const currentCheckpoint = trackCheckpointSelection?.checkpoint;
  return (
    <CustomDropdown
      buttonText={
        currentTrack && currentCheckpoint
          ? `${currentTrack} ${currentCheckpoint}`
          : "Select Checkpoint"
      }
      dropdownHeader="Track"
      buttonProps={{
        color: "transparent",
        block: true,
      }}
      parentAnchor={[anchorEl, setAnchorEl]}
      dropPlacement="bottom"
      dropdownList={availableLessonTrackArray
        .sort((a, b) => (b > a ? 1 : -1))
        .map((track, i) => (
          <CustomDropdown
            dropdownHeader="Checkpoint"
            data-ref="multi"
            onClick={(item) => {
              const { track, checkpoint } = item.props;
              setTrackCheckpointSelection({
                track,
                checkpoint,
              });
              setAnchorEl(null);
            }}
            key={`track{${i}}`}
            innerDropDown
            buttonText={track}
            buttonProps={{
              color: "transparent",
              block: true,
            }}
            caret={false}
            hover
            dropPlacement="right"
            dropdownList={Object.keys(allLessonsObj[track])
              .sort((a, b) => (b > a ? 1 : -1))
              .map((checkpoint, i) => (
                <div checkpoint={checkpoint} track={track} key={i}>
                  Checkpoint {checkpoint}
                </div>
              ))}
          />
        ))}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  allLessonsObj: selectAllLessonsObj,
  availableLessonTrackArray: selectAvailableLessonTrackArray,
  trackCheckpointSelection: selectTrackCheckpointSelection,
});

const mapDispatchToProps = (dispatch) => ({
  setTrackCheckpointSelection: (selectionObj) =>
    dispatch(setTrackCheckpointSelection(selectionObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDropDownMultiLevelList);
