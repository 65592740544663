// Utilities
import moment from 'moment';
import { retrieveAirtableRecord } from "../../utils/firebase/airtable-api-calls.utils";
import { realtimeDB } from "../../utils/firebase/firebase-utils";
import { groupsTableFieldNames } from "../../utils/airtable/ids-and-fieldnames/airtable-groups-table-fieldnames.utils";
import { studentsTableFieldnames } from "utils/airtable/ids-and-fieldnames/airtable-students-table-fieldnames.utils";
import { mainbaseTableIds } from "../../utils/airtable/ids-and-fieldnames/airtable-mainbase-table-ids.utils";

// Redux actions selectors and types
import { fetchLessonsByCohortAsyncStart } from "../lessons/lessons.actions";
import { selectCurrentGroup, selectLastRefreshed } from "./groups.selectors";
import { GroupsActionTypes } from "./groups.types";
import { selectCurrentUser } from "../user/user.selectors";
import {
  combineReportObjectsWithChecklists,
  fetchStudentsByIDs,
  getGroupRecordsLinkedToTutor,
  getReportsForGroup,
  getTutorRecordByID,
  getGroupRecordByID,
  buildBilledGroup,
} from "../../utils/airtable/airtable.utils";
import { fetchNewsStartAsync } from "redux/news/news.actions";

export const setCurrentGroup = (group) => ({
  type: GroupsActionTypes.SET_CURRENT_GROUP,
  payload: group,
});

export const setLastRefreshed = (date) => ({
  type: GroupsActionTypes.SET_LAST_REFRESHED,
  payload: date,
});

export const resetGroups = () => ({
  type: GroupsActionTypes.RESET_GROUPS,
});

export const fetchGroupsStart = () => ({
  type: GroupsActionTypes.FETCH_GROUPS_START,
});
export const fetchGroupsSuccess = (groupsMap) => ({
  type: GroupsActionTypes.FETCH_GROUPS_SUCCESS,
  payload: groupsMap,
});

export const fetchGroupsFailure = (errorMessage) => ({
  type: GroupsActionTypes.FETCH_GROUPS_FAILURE,
  payload: errorMessage,
});

export const fetchStudentsStart = () => ({
  type: GroupsActionTypes.FETCH_STUDENTS_START,
});
export const fetchStudentsSuccess = (studentsMap) => ({
  type: GroupsActionTypes.FETCH_STUDENTS_SUCCESS,
  payload: studentsMap,
});

export const fetchStudentsFailure = (errorMessage) => ({
  type: GroupsActionTypes.FETCH_STUDENTS_FAILURE,
  payload: errorMessage,
});

export const setStudentsLiveStatus = (studentObj) => ({
  type: GroupsActionTypes.SET_STUDENTS_LIVE_STATUS,
  payload: studentObj,
});

export const updateStudentLiveStatus = (studentObj) => ({
  type: GroupsActionTypes.UPDATE_STUDENT_LIVE_STATUS,
  payload: studentObj,
});

export const fetchReportsStart = () => ({
  type: GroupsActionTypes.FETCH_REPORTS_START,
  payload: undefined,
});

export const fetchReportsSuccess = (reports) => ({
  type: GroupsActionTypes.FETCH_REPORTS_SUCCESS,
  payload: reports,
});

export const fetchReportsFailure = (errorMessage) => ({
  type: GroupsActionTypes.FETCH_REPORTS_FAILURE,
  payload: errorMessage,
});

export const refreshCurrentGroupStart = () => ({
  type: GroupsActionTypes.REFRESH_GROUP_START,
  payload: undefined,
});

export const refreshCurrentGroupSuccess = (group) => ({
  type: GroupsActionTypes.REFRESH_GROUP_SUCCESS,
  payload: group,
});

export const refreshCurrentGroupFailure = (errorMessage) => ({
  type: GroupsActionTypes.REFRESH_GROUP_FAILURE,
  payload: errorMessage,
});

// Fetches all the groups related attached to a tutor and then initializes the
// current group accordingly
export const fetchGroupsStartAsync = () => {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = selectCurrentUser(state);

    if (!currentUser?.admin) return;

    dispatch(fetchGroupsStart());

    getTutorRecordByID(currentUser.id)
      .then((res) => {
        // If we get back a tutor record, load up all linked group records
        if (res?.data?.length) {
          return getGroupRecordsLinkedToTutor(res.data);
        } else {
          throw Error("No Tutor Record found on Airtable");
        }
      })
      .then((res) => {
        dispatch(setInitialCurrentGroupAfterLoad(res));
        dispatch(fetchGroupsSuccess(res));
      })

      .catch((err) => {
        dispatch(fetchGroupsFailure(err.message));
      });
  };
};

// Initializes the current group to either use local storage data (updated by the recent fetch)
// Or sets the current group to null triggering a group selection screen
export const setInitialCurrentGroupAfterLoad =
  (fetchedGroups) => (dispatch, useState) => {
    // If there is a current group selected, check if the group exists in
    // assigned groups and the load the group if so,
    // Otherwise reset current group to null
    const state = useState();
    const currentGroup = selectCurrentGroup(state);
    const lastRefreshedState = selectLastRefreshed(state);
    const lastRefreshedParsed = moment(lastRefreshedState, moment.ISO_8601)
    const shouldRefresh = lastRefreshedParsed.isValid() ? lastRefreshedParsed.isBefore(moment().subtract(12, 'hour')) : true
    console.log(`SHOULD REFRESH: ${shouldRefresh}`)
    // If the current group exists, find it in the returned data and replace currentGroup
    // with the updated value.
    if (
      currentGroup?.id &&
      !shouldRefresh &&
      fetchedGroups.find((group) => group?.id === currentGroup?.id)
    ) {
      // Set current group to the updated current group
      const updatedCurrentGroup = fetchedGroups.find(
        (group) => group?.id === currentGroup?.id
      );
      dispatch(loadCurrentGroup(updatedCurrentGroup));

      // If currentGroup is selected in local storage but
      // it doesn't appear our data response, set the current group to null
      // This accounts for being removed from a group.
    } else if (currentGroup) {
      dispatch(setCurrentGroup(null));
      dispatch(setLastRefreshed(moment().format()));
    } else if (!currentGroup) {
      dispatch(setLastRefreshed(moment().format()));
    }
  };

// Fetches full student record 1 by one with all fields from an array of student record IDs
// Not currently in use March 2022
export const fetchStudentsByRetrieveRecordStartAsync = (studentIdArr) => {
  return (dispatch) => {
    dispatch(fetchStudentsStart());

    const promiseArr = studentIdArr.map((recordId) =>
      retrieveAirtableRecord({ tableName: mainbaseTableIds.students, recordId })
    );
    Promise.allSettled(promiseArr)
      .then((values) => {
        const studentsMap = values
          .filter((item) => item.status === "fulfilled")
          .map((item) => item.value.data);
        dispatch(fetchStudentsSuccess(studentsMap));
      })
      .catch((err) => dispatch(fetchStudentsFailure(err.message)));
  };
};

// Removes all snapshot listeners for students that aare current in groups.studentsLiveStatus
// It then resets groups.studentsLiveStatus to an empty object "{}
export const resetStudentsLiveStatusState = () => {
  return (dispatch, getState) => {
    const prevStudentsStatus = getState().groups.studentsLiveStatus;

    for (let key in prevStudentsStatus) {
      prevStudentsStatus[key].realtimeUpdateRef.off();
    }

    dispatch(setStudentsLiveStatus({}));
  };
};

// Fetches all student data from airtable for a given group
// If the group has a reporting period attached it will load up report data
export const fetchStudentsStartAsync = (
  studentIdArr,
  groupId,
  reportingPeriodID = []
) => {
  return (dispatch) => {
    if (!studentIdArr?.length) {
      const error = new Error(
        "warning: fetchStudentsStartAsync was called without any students."
      );
      console.error(error.message);
      return;
    }
    dispatch(fetchStudentsStart());
    // Removes snapshot listeners from all current students/
    dispatch(resetStudentsLiveStatusState());

    // Attaches snapshot listeners to new students
    /* Assigns a student's "allegiance" depending on their current and usual groups:
    "Normal" - The groupId passed into fetchStudentsStartAsync() is present 
    in the students current group/s and usual group/s 
    "Refugee" - The groupId passed in is present in the student's current group/s
    but not present in the student's usual group
    "Turncoat" - The groupId passed in is present in the student's usual group/s
    but not present in the student's current group.

    This is used to show all students that are related to the group 
    that the tutor is currently looking at, but render elements conditionally 
    for different students in the group (e.g disabled feedback for "turncoat" students)
    */
    // Filters out students that don't have a current group assigned
    /* Orders students according to "allegiance", so as to render with 
  "refugees" first, "normals" second, and "turncoats" third */
    fetchStudentsByIDs(studentIdArr)
      .then((res) => {
        const students = res.data.map((student) => {
          const realtimeUpdateRef = realtimeDB.ref(`/status/${student.id}`);

          realtimeUpdateRef.on("value", (snapshot) => {
            const data = snapshot.val();

            const studentObj = {
              id: student.id,
              realtimeUpdateRef,
              ...data,
            };
            dispatch(updateStudentLiveStatus(studentObj));
          });

          const currentGroup =
            student?.[studentsTableFieldnames.group_id_current_gr] ?? [];
          const usualGroup =
            student?.[studentsTableFieldnames.group_id_usual_gr] ?? [];
          if (
            currentGroup?.includes(groupId) &&
            usualGroup?.includes(groupId)
          ) {
            return { ...student, allegiance: "Normal" };
          } else if (
            currentGroup?.includes(groupId) &&
            !usualGroup?.includes(groupId)
          ) {
            return { ...student, allegiance: "Refugee" };
          } else if (
            !currentGroup?.includes(groupId) &&
            usualGroup?.includes(groupId)
          ) {
            return { ...student, allegiance: "Turncoat" };
          }
        });
        const filteredStudents = students.filter(
          (student) =>
            student?.[studentsTableFieldnames.group_id_current_gr] !== undefined
        );
        filteredStudents.sort((a, b) => {
          const order = { Refugee: 1, Normal: 2, Turncoat: 3 };
          return order[a.allegiance] - order[b.allegiance];
        });
        dispatch(fetchStudentsSuccess(filteredStudents));
        // Grab all reports the group has a reporting period attached
        if (reportingPeriodID.length > 0) {
          dispatch(fetchReportsStart());
          getReportsForGroup(
            reportingPeriodID[0],
            filteredStudents.map((student) => {
              return student.id;
            })
          )
            .then((res) => {
              // If there are no reports, acknowledge successful
              // return and dispatch with payload of null
              if (!res?.data?.length) {
                return null;
              } else {
                return combineReportObjectsWithChecklists(res.data);
              }
            })
            .then((reportsObj) => dispatch(fetchReportsSuccess(reportsObj)))
            .catch((err) => {
              console.error(err);
              dispatch(fetchReportsFailure(err.message));
            });
        }
      })
      .catch((err) => dispatch(fetchStudentsFailure(err.message)));
  };
};

// Gets dispatched whenever a group is selected and is also used by setInitialCurrentGroupAfterLoad
export const loadCurrentGroup = (group) => {
  return (dispatch) => {
    dispatch(setCurrentGroup(group));
    dispatch(fetchNewsStartAsync(group));

    const studentsIDArr =
      group?.[groupsTableFieldNames.link_to_students_table] ?? [];
    const reportingPeriodID =
      group?.[groupsTableFieldNames.reporting_period] ?? [];
    const usualStudentsIDArr =
      group?.[groupsTableFieldNames.groupid_usual] ?? [];
    const groupId = group?.id;
    const allStudentsArr = [...studentsIDArr, ...usualStudentsIDArr];

    if (allStudentsArr.length > 0) {
      dispatch(
        fetchStudentsStartAsync(allStudentsArr, groupId, reportingPeriodID)
      );
    }

    dispatch(
      fetchLessonsByCohortAsyncStart(
        group[groupsTableFieldNames.cohort_name_string_co] || []
      )
    );
  };
};

export const refreshCurrentGroup = () => {
  return (dispatch, getState) => {
    const currentGroupId = getState().groups?.currentGroup?.id;
    dispatch(refreshCurrentGroupStart());
    getGroupRecordByID(currentGroupId)
      .then((res) => res.data)
      .then((groupRecordArr) => {
        if (!groupRecordArr?.length) {
          const err = `Could not find group with ID: ${currentGroupId}`;
          console.error(err);
          dispatch(refreshCurrentGroupFailure(err));
        } else {
          const newGroup = groupRecordArr[0];
          return buildBilledGroup(newGroup);
        }
      })
      .then((billedGroup) => {
        dispatch(refreshCurrentGroupSuccess(billedGroup));
        // Start student refresh action. This is heavily reliant on but
        // separate to the groups refresh
        dispatch(
          fetchStudentsStartAsync(
            billedGroup[groupsTableFieldNames.link_to_students_table],
            billedGroup.id
          )
        );
        const currentGroups = getState()?.groups.groups;
        const newGroups = currentGroups.map((group) => {
          if (group.id === billedGroup?.id) {
            return billedGroup;
          } else {
            return group;
          }
        });
        // Very hacky, but instead of fetching all the groups again we
        // push the new group into the current allGroup state, and pretend
        // it was a success
        dispatch(fetchGroupsSuccess(newGroups));
      })
      .catch((errMessage) => {
        console.error(errMessage);
        dispatch(refreshCurrentGroupFailure(errMessage));
      });
  };
};
