// Modules
import React, { useEffect } from "react";

// MUI
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Components
import CardHeader from "../../material-kit-pro-components/Card/CardHeader";
import Card from "../../material-kit-pro-components/Card/Card";
import CardBody from "../../material-kit-pro-components/Card/CardBody";

// Assets and styling
import {
  primaryColor,
  whiteColor,
} from "../../assets/jss/material-kit-pro-react";

const StyledTabs = withStyles({
  root: { minHeight: 0 },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
})((props) => <Tabs variant="scrollable" scrollButtons={"auto"} {...props} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: whiteColor,
    opacity: 1,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    minWidth: "80px",
  },
  selected: {
    color: primaryColor[0],
    backgroundColor: whiteColor,
    borderRadius: "5px 5px 0px 0px",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: "5px",
  },
  labelIcon: {
    minHeight: "100%",
  },
}))((props) => <Tab disableRipple {...props} />);

const CustomTabCard = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setValue(0);
  }, [tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabArr = tabs || [];

  return (
    <Card>
      <CardHeader
        color="primary"
        plain
        style={{
          minHeight: "3.5rem",
          display: "flex",
          alignItems: "end",
          paddingBottom: "0px",
          boxShadow: "none",
        }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          // scrollButtons={tabArr.length > 1 ? "auto" : "off"}
        >
          {tabArr.map((tab, i) => (
            <StyledTab key={`tab${i}`} label={tab?.label} icon={tab?.icon} />
          ))}
        </StyledTabs>
      </CardHeader>
      <CardBody>{tabArr[value]?.content}</CardBody>
    </Card>
  );
};

export default CustomTabCard;
