/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserLessonData } from "../redux/user/user.selectors";

const useLessonProgress = (lesson) => {
  const lessonData = useSelector(selectUserLessonData(lesson.id));

  const progress = useMemo(() => {
    const levelChallenges = lesson.challenges.filter((challenge) => {
      return challenge.challengeType.includes("level");
    });

    const totalChallenges = levelChallenges.length;

    let completedChallenges = 0;
    if (lessonData?.completedChallenges?.length) {
      completedChallenges = levelChallenges.filter((challenge) =>
        lessonData?.completedChallenges?.includes(challenge.id)
      ).length;
    }

    const progress = completedChallenges / totalChallenges;
    return progress;
  }, [lessonData]);

  return progress;
};

export default useLessonProgress;
