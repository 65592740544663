export const globalStyles = {
  overflow: "overlay",
  "&::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: "16px",
    height: "16px",
  },

  /* background of the scrollbar except button or resizer */
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff",
  },

  /* scrollbar itself */
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#babac0",
    borderRadius: "16px",
    border: "4px solid #fff",
  },

  /* set button(top and bottom of the scrollbar) */
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
};
