import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { whiteColor } from "../../assets/jss/material-kit-pro-react";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(styles);

const Header = (props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const {
    color,
    rightLinks,
    leftLinks,
    bottomRightLinks,
    bottomLeftLinks,
    brand,
    logo,
    fixed,
    absolute,
    hideHeader,
  } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.whiteBoxShadow]: bottomRightLinks || bottomLeftLinks,
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;
  return (
    <AppBar
      className={appBarClasses}
      style={{ paddingBottom: 0, paddingTop: 0 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          margin: 0,
          width: "100%",
        }}
      >
        {!hideHeader && (
          <Toolbar className={classes.toolbar}>
            {logo && (
              <img
                className={classes.logo}
                src={require("assets/img/Dark_background.png").default}
              />
            )}
            <div className={classes.flex}>
              {leftLinks ? { leftLinks } : brandComponent}
            </div>
            <Hidden xsDown implementation="css">
              {rightLinks}
            </Hidden>
            <Hidden smUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Toolbar>
        )}
        {(bottomRightLinks || bottomLeftLinks) && (
          <Hidden xsDown>
            <AppBar
              position="sticky"
              style={{
                backgroundColor: whiteColor,
              }}
            >
              <Toolbar
                className={classes.toolbar + " bottom"}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>{bottomLeftLinks}</Box>
                <Box>{bottomRightLinks}</Box>
              </Toolbar>
            </AppBar>
          </Hidden>
        )}
      </div>

      <Hidden smUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          elevation={16}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
            {bottomLeftLinks}
            {bottomRightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};

export default Header;
