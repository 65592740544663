import { cloudFunctions } from "./firebase-utils";

const baseId = "appRnmsfuJPOiO1IF";

export const retrieveAirtableRecord = ({ tableName, recordId }) =>
  cloudFunctions.httpsCallable("retrieve_record")({
    baseId,
    tableName,
    recordId,
  });

export const listAirtableRecords = ({ tableName, selectObject }) =>
  cloudFunctions.httpsCallable("list_records")({
    baseId,
    tableName,
    selectObject,
  });

export const submitTutorFeedback = (feedbackId) =>
  cloudFunctions.httpsCallable("submit_tutor_feedback")({
    feedbackId,
  });

export const submitTutorReports = (reportIDs) =>
  cloudFunctions.httpsCallable("submit_reports")({
    reportIDs,
  });
