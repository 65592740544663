export const groupsTableFieldNames = {
  group_id_gr: "group_id_gr[SYS]",
  current_lesson_id_co_le: "current_lesson_id_co_le[SYS]",
  lesson_topic: "lesson_topic[SYS]",
  primary_tutor_first_name: "primary_tutor_firstname_tu[SYS]",
  secondary_tutor_firstname: "secondary_tutor_firstname_tu[SYS]",
  lesson_day_gr: "lesson_day_gr[SYS]",
  link_to_students_table: "link_to_students_table[SYS]",
  groupid_usual: "groupid_usual",
  group_login_links_gr: "group_login_links_gr[SYS]",
  student_feedback: "student_feedback_links[SYS]",
  current_lesson_record_id: "current_lesson_record_co_le[SYS]",
  cohort_name_string_co: "cohort_name_string_co[SYS]",
  lesson_time_gr: "lesson_time_gr[SYS]",
  group_type_gr: "group_type_gr[SYS]",
  reporting_period: "reporting_period[SYS]",
  primary_tutor_link: "primary_tutor_link[SYS]",
  secondary_tutor_link: "secondary_tutor_link[SYS]",
  tertiary_tutor_link: "tertiary_tutor_link[SYS]",
};
