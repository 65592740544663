export const lessonPreviewAccordionStyle = {
  accordionRoot: {
    borderRadius: "8px",
    margin: "15px 0",
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    "&:before": {
      backgroundColor: "transparent",
    },
  },
  accordionSummaryExpanded: {
    minHeight: 0,
    "&.MuiAccordionSummary-root": {
      backgroundColor: "rgba(100, 83, 153, 0.2)",
      borderRadius: "5px 5px 0 0",
    },
  },
};
