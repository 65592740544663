// Modules
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Redux actions and selectors
import {
  setTutorFeedbackDashboard,
  setTutorSchedule,
} from "../../redux/user/user.actions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// Assets and styling
import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import RegularButton from "../../material-kit-pro-components/CustomButtons/Button";
import { ChevronLeft } from "@material-ui/icons";

const useHeaderLinksStyle = makeStyles(headerLinksStyle);

const SubHeaderBackToLessons = ({ setTutorFeedbackDashboard }) => {
  const classes = useHeaderLinksStyle();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link
          onClick={() => {
            setTutorSchedule(false);
            setTutorFeedbackDashboard(false);
          }}
          to="/"
        >
          <RegularButton
            simple
            size="lg"
            color={"primary"}
            style={{ margin: 0 }}
          >
            <ChevronLeft />

            <strong>Back to Lessons</strong>
          </RegularButton>
        </Link>
      </ListItem>
    </List>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTutorSchedule: (bool) => dispatch(setTutorSchedule(bool)),
  setTutorFeedbackDashboard: (bool) =>
    dispatch(setTutorFeedbackDashboard(bool)),
});

export default connect(null, mapDispatchToProps)(SubHeaderBackToLessons);
