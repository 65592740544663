import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components

import Button from "../CustomButtons/Button.js";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { auth } from "../../utils/firebase/firebase-utils";

const useHeaderLinksStyle = makeStyles(headerLinksStyle);

const HeaderLinks = ({ currentUser }) => {
  const classes = useHeaderLinksStyle();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {currentUser ? (
          <Button
            onClick={() => auth.signOut()}
            color="transparent"
            className={classes.navLink}
          >
            <strong>Sign Out</strong>
          </Button>
        ) : (
          <Link to="/login" className={classes.navLink}>
            <strong>Sign In</strong>
          </Link>
        )}
      </ListItem>
    </List>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(HeaderLinks);
