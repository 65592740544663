/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import {
  selectGroupsErrorMessage,
  selectStudentsErrorMessage,
} from "../redux/groups/groups.selectors";
import { selectNewsErrorMessage } from "../redux/news/news.selectors";
import { selectLessonsErrorMessage } from "../redux/lessons/lessons.selectors";
import { fetchLessonsAndGroupsAsyncStart } from "../redux/user/user.actions";
import { selectUserErrorMessage } from "../redux/user/user.selectors";

const useAllError = () => {
  const lessonsErrorMessage = useSelector(selectLessonsErrorMessage);
  const groupsErrorMessage = useSelector(selectGroupsErrorMessage);
  const studentsErrorMessage = useSelector(selectStudentsErrorMessage);
  const userErrorMessage = useSelector(selectUserErrorMessage);
  const newsErrorMessage = useSelector(selectNewsErrorMessage);
  const fetchLessonsAndGroups = useDispatch(fetchLessonsAndGroupsAsyncStart);

  if (
    lessonsErrorMessage ||
    groupsErrorMessage ||
    studentsErrorMessage ||
    userErrorMessage ||
    newsErrorMessage
  ) {
    return {
      messages: {
        lessons: lessonsErrorMessage,
        groups: groupsErrorMessage,
        students: studentsErrorMessage,
        user: userErrorMessage,
        news: newsErrorMessage,
      },
      onRetry: fetchLessonsAndGroups,
    };
  } else {
    return null;
  }
};

export default useAllError;
