// Modules
import React from "react";
import { Link } from "react-router-dom";

// MUI
import { makeStyles } from "@material-ui/core";
import { PlayArrowRounded } from "@material-ui/icons";

// Components
import RegularButton from "../../../material-kit-pro-components/CustomButtons/Button";

// Assets and styling
import buttonGroupStyle from "../../../assets/jss/material-kit-pro-react/buttonGroupStyle";

const styles = {
  ...buttonGroupStyle,
};
const useStyles = makeStyles(styles);

const StudentStartButton = ({ lesson }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonGroup} style={{ marginRight: "20px" }}>
      <Link to={`/lessons/${lesson?.id}`}>
        <RegularButton size="sm" color="primary">
          <PlayArrowRounded />
          Start Lesson
        </RegularButton>
      </Link>
    </div>
  );
};

export default StudentStartButton;
