// Modules
import React, { Suspense } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Redux selectors
import {
  selectCurrentUser,
  selectStudentMode,
} from "../../../redux/user/user.selectors";

// Components
import StudentStartButton from "./StudentStartButton.component";

// Tutor functionality codesplit
const AdminStartButton = React.lazy(() =>
  import("./AdminStartButton.component")
);
// import AdminStartButton from "./AdminStartButton.component";

const StartButtonCombined = ({ lesson, currentUser, studentMode }) => {
  if (currentUser?.admin && !studentMode) {
    return (
      <Suspense fallback={<StudentStartButton {...{ lesson }} />}>
        <AdminStartButton {...{ lesson }} />
      </Suspense>
    );
  } else {
    return <StudentStartButton {...{ lesson }} />;
  }
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  studentMode: selectStudentMode,
});

export default connect(mapStateToProps)(StartButtonCombined);
