import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CustomCheckBox from "./CustomCheckBox.component";
import HelpIcon from "@material-ui/icons/Help";
import Button from "../../../material-kit-pro-components/CustomButtons/Button.js";
import { handleHints } from "utils/helpers";

const DataRenderedCheckBox = ({
  classes,
  item,
  challengeId,
  i,
  completedChallengesArr,
  checkedArr,
  setCheckedArr,
  setInTextHints,
}) => {
  // The initial state of the checkbox when it gets rendered
  const checkedState = useMemo(
    () => (completedChallengesArr.includes(challengeId) ? true : false),
    [challengeId, completedChallengesArr]
  );

  const handleChecked = () => {
    const id = `${challengeId}_${i}`;
    const newCheckedArr = Array.from(new Set([...checkedArr, id]));
    setCheckedArr(newCheckedArr);
  };

  const handleUnchecked = () => {
    const id = `${challengeId}_${i}`;
    const newCheckedArr = checkedArr.filter((item) => item !== id);
    setCheckedArr(newCheckedArr);
  };

  const { textWithoutHints, hintContentArray } = handleHints(item);

  return (
    <div
      style={{
        marginBottom: "15px",
        marginLeft: "15px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <CustomCheckBox
        label={
          <div className={classes.reactMarkdown}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {textWithoutHints}
            </ReactMarkdown>
          </div>
        }
        onChecked={handleChecked}
        onUnchecked={handleUnchecked}
        {...{ checkedState }}
      />
      {hintContentArray.length > 0 ? (
        <Button
          onClick={() =>
            setInTextHints({ show: true, hintArray: hintContentArray })
          }
          color="hint"
          size="sm"
          style={{ padding: "8px 16px", margin: 0 }}
        >
          <HelpIcon style={{ fontSize: 50 }} />
          Hint
        </Button>
      ) : null}
    </div>
  );
};

export default DataRenderedCheckBox;
