import { combineReducers } from "redux";
import groupsReducer from "./groups/groups.reducer";
import lessonsReducer from "./lessons/lessons.reducer";
import userReducer from "./user/user.reducer";
import newsReducer from "./news/news.reducer";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";

const groupsPersistConfig = {
  key: "groups",
  storage,
  whitelist: ["currentGroup", "lastRefreshed"],
};

const rootReducer = combineReducers({
  user: userReducer,
  lessons: lessonsReducer,
  groups: persistReducer(groupsPersistConfig, groupsReducer),
  news: newsReducer,
});

export default rootReducer;
