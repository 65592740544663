import React from "react";

const DashboardNameDisplay = ({ currentUser }) => {
  return !currentUser?.admin ? (
    <div
      style={{ display: "flex", flexDirection: "column", lineHeight: "20px" }}
    >
      <strong style={{ marginRight: "10px" }}>
        {currentUser?.displayName}
      </strong>
      <small style={{ color: "#FFF" }}>{currentUser?.username}</small>
    </div>
  ) : (
    <strong>{currentUser?.displayName || "RocketHour"}</strong>
  );
};

export default DashboardNameDisplay;
