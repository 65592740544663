import {
  getCurrentLessons,
  getLessonsByIdArr,
  getPreviousLessons,
  getSingleLesson,
  getUserLessonDocs,
} from "../../utils/firebase/firestore-utils";
import {
  fetchUserLessonDataAsyncStart,
  fetchUserLessonDataStart,
  fetchUserLessonDataSuccess,
} from "../user/user.actions";
import { selectCurrentUser } from "../user/user.selectors";
import { LessonsActionTypes } from "./lessons.types";

export const addToLessonStore = (lesson) => ({
  type: LessonsActionTypes.ADD_LESSON_TO_STORE,
  payload: lesson,
});

export const setThisWeeksLessons = (lesson) => ({
  type: LessonsActionTypes.SET_THIS_WEEKS_LESSONS,
  payload: lesson,
});

export const resetLessons = () => ({
  type: LessonsActionTypes.RESET_LESSONS,
  payload: undefined,
});

export const setTrackCheckpointSelection = (selectionObj) => ({
  type: LessonsActionTypes.SET_TRACK_CHECKPOINT_SELECTION,
  payload: selectionObj,
});

// Actions to use with async redux thunk action
export const fetchLessonsStart = () => ({
  type: LessonsActionTypes.FETCH_LESSONS_START,
  payload: undefined,
});

export const fetchLessonsSuccess = (lessonsMap) => ({
  type: LessonsActionTypes.FETCH_LESSONS_SUCCESS,
  payload: lessonsMap,
});

export const fetchLessonsFailure = (errorMessage) => ({
  type: LessonsActionTypes.FETCH_LESSONS_FAILURE,
  payload: errorMessage,
});

export const fetchCurrentLessonStart = () => ({
  type: LessonsActionTypes.FETCH_CURRENT_LESSON_START,
  payload: undefined,
});

export const fetchCurrentLessonSuccess = (lessonsMap) => ({
  type: LessonsActionTypes.FETCH_CURRENT_LESSON_SUCCESS,
  payload: lessonsMap,
});

export const fetchCurrentLessonFailure = (errorMessage) => ({
  type: LessonsActionTypes.FETCH_CURRENT_LESSON_FAILURE,
  payload: errorMessage,
});

// Below functions utilize redux-thunk

// Used to get lessons by passing in a specific cohort array.
// This ignores any past lessons the current user has started/completed and
// Only grabs lessons currently assigned to the cohorts passed in. (Should generally
// just be 1 cohort but has capabilities for more)
export const fetchLessonsByCohortAsyncStart =
  (cohortArr) => (dispatch, getState) => {
    const { user } = getState();
    dispatch(resetLessons());
    dispatch(fetchLessonsStart());

    dispatch(fetchUserLessonDataAsyncStart(user));

    Promise.all([getCurrentLessons(cohortArr), getPreviousLessons(cohortArr)])
      .then((valuesArr) => {
        const [currentLessonsObj, previousLessonsObj] = valuesArr;

        // Throw an error if there are no lessons assigned
        if (
          !(currentLessonsObj && Object.keys(currentLessonsObj).length) &&
          !(previousLessonsObj && Object.keys(previousLessonsObj).length)
        ) {
          throw new Error("No lessons were assigned to this group.");
        }

        dispatch(fetchLessonsSuccess(valuesArr));
      })
      .catch((err) => {
        dispatch(fetchLessonsFailure(err.message));
      });
  };

// Fetch all the lessons assigned to the cohorts on the currentUser
export const fetchLessonsAsyncStart = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentUser = selectCurrentUser(state);

    if (!currentUser) return;
    dispatch(fetchLessonsStart());
    try {
      const cohortArr = currentUser?.cohort || [];

      let [currentLessonsObj, previousLessonsObj] = await Promise.all([
        getCurrentLessons(cohortArr),
        getPreviousLessons(cohortArr),
      ]);

      dispatch(fetchUserLessonDataStart());

      const res = await getUserLessonDocs(currentUser);

      dispatch(fetchUserLessonDataSuccess(res));

      // If user is not an admin, load up all past completed lessons regardless of what is
      // Assigned to the cohort.
      if (!currentUser.admin) {
        const allLessons = { ...currentLessonsObj, ...previousLessonsObj };
        const additionalLessonsIdArr = Object.keys(res).filter(
          (lessonId) => !(lessonId in allLessons)
        );

        let otherLessonsPbj = {};
        if (additionalLessonsIdArr.length) {
          otherLessonsPbj = await getLessonsByIdArr(additionalLessonsIdArr);
        }
        previousLessonsObj = { ...previousLessonsObj, ...otherLessonsPbj };
      }
      // Throw an error if there are no lessons to load up
      if (
        !(currentLessonsObj && Object.keys(currentLessonsObj).length) &&
        !(previousLessonsObj && Object.keys(previousLessonsObj).length)
      ) {
        throw new Error("No lessons were assigned to this group.");
      }

      dispatch(fetchLessonsSuccess([currentLessonsObj, previousLessonsObj]));
    } catch (err) {
      console.error(err);
      dispatch(fetchLessonsFailure(err.message));
    }
  };
};

// This is a potentially unused feature but was requested in the beginning
// It enables admins to set a user type to any string that includes current_lessons
// And that will trigger a fetch of only lessons in current lessons.
export const fetchCurrentLessonsOnlyAsyncStart = () => {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = selectCurrentUser(state);
    const cohortArr = currentUser?.cohort;

    dispatch(resetLessons());
    dispatch(fetchLessonsStart());

    getCurrentLessons(cohortArr)
      .then((data) => {
        dispatch(fetchLessonsSuccess([data, {}]));
      })
      .catch((err) => {
        dispatch(fetchLessonsFailure(err.message));
      });
  };
};

// Fetch single lesson for admin dev page
export const fetchCurrentLesson = (lessonId) => {
  return async (dispatch) => {
    // const state = getState();

    dispatch(fetchCurrentLessonStart());
    try {
      let currentLessonsObj = await getSingleLesson(lessonId);

      dispatch(fetchCurrentLessonSuccess(currentLessonsObj));
    } catch (err) {
      console.error(err);
      dispatch(fetchCurrentLessonFailure(err.message));
    }
  };
};
