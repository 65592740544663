// Modules
import React, { useEffect, useState } from "react";

// Material
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";

// Assets and styling
import { default as checkboxStyles } from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";

const styles = {
  ...checkboxStyles,
  labelExtra: {
    color: "black",
  },
  labelClick: {
    textDecoration: "line-through",
  },
  checkboxAlign: {
    display: "flex",
    alignItems: "center",
  },
};

const useStyles = makeStyles(styles);

const CustomCheckBox = ({ label, onChecked, onUnchecked, checkedState }) => {
  const [checked, setChecked] = useState(false);

  const classes = useStyles();

  // If passed an onChecked/onUnchecked call back function
  // execute here whenever state changes
  useEffect(() => {
    if (checked && onChecked) {
      onChecked();
    } else if (onUnchecked) {
      onUnchecked();
    }
  }, [checked]);

  useEffect(() => {
    if (checkedState !== undefined) {
      setChecked(checkedState);
    }
  }, [checkedState]);

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            tabIndex={-1}
            onClick={() => setChecked(!checked)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{ checked: classes.checked }}
          />
        }
        classes={{
          label: `${classes.checkboxAlign} ${classes.label} ${
            classes.labelExtra
          } ${checked ? classes.labelClick : ""}`,
        }}
        label={label}
      />
    </div>
  );
};

export default CustomCheckBox;
