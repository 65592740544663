import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  studentMode: false,
  tutorSchedule: false,
  tutorFeedbackDashboard: false,
  userLessonData: {},
  userLessonDataLoading: false,
  errorMessage: undefined,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UserActionTypes.SET_STUDENT_MODE:
      return {
        ...state,
        studentMode: action.payload,
      };
    case UserActionTypes.SET_TUTOR_SCHEDULE:
      return {
        ...state,
        tutorSchedule: action.payload,
      };
    case UserActionTypes.SET_TUTOR_FEEDBACK_DASHBOARD:
      return {
        ...state,
        tutorFeedbackDashboard: action.payload,
      };
    case UserActionTypes.FETCH_USER_LESSON_DATA_START:
      return {
        ...state,
        userLessonDataLoading: true,
        errorMessage: undefined,
      };
    case UserActionTypes.FETCH_USER_LESSON_DATA_SUCCESS:
      return {
        ...state,
        userLessonData: action.payload,
        userLessonDataLoading: false,
      };
    case UserActionTypes.FETCH_USER_LESSON_DATA_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        userLessonDataLoading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
