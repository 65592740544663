export const mainbaseTableIds = {
  students: "tblohZTDmoxmyfBwE",
  parents: "tbltGMeVLotCruU7i",
  groups: "tbly5dlu3WLUk90tc",
  tutors: "tblyjv66lKHkYDoLP",
  cohort_lesson_matching: "tbl9iz5nK9EKnkMBR",
  lessons: "tbl1gqP1740LMwzbJ",
  lesson_challenges: "tblYmRtB1GkslSJAD",
  lesson_s3_product_assets: "tblGyseZctERQ44Tf",
  feedback_students: "tblI64GpEjYbkZVu1",
  feedback_lessons: "tbl9BKcWPti8aBkO5",
  tutor_prep: "tblY1wQlRN8cCDdTP",
  reports_old: "tblepJwnmbRWZmMaJ",
  reports: "tbl3R1nEnLkvASxro",
  report_templates: "tblWhFRsmIvSRLf20",
  report_template_checklists: "tblGsz5UlIyUE4zbj",
  student_registrations: "tbl8Sg5eUG1KKrscg",
  packages: "tblt3MTcesyH6U5qE",
  starter_data_c4: "tblX01SiJnrMfvZiW",
  starter_data_c5: "tblVOfbPJyxNEwMSE",
  starter_data_staff: "tblBKrtTIiPILuqG9",
  booster_sessions: "tblKmlMKxBQnNP1BN",
  tech_checks: "tbl6qVFPc5QtaXdM5",
};
