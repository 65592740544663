// Modules
import React from "react";

const RocketLoader = ({ width, style, size }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: size === "sm" ? "0" : "20px",
      }}
    >
      <img
        src={require("assets/img/rockethour-loader102.svg").default}
        width={width ? width : size === "sm" ? "20px" : "50px"}
        style={{ ...style }}
      />
    </div>
  );
};

export default RocketLoader;
