// Modules
import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

// Redux actions and selectors
import {
  selectMultipleTracksOrCheckpointsPresent,
  selectPreviousLessonsArr,
} from "../../../redux/lessons/lessons.selectors.js";

// Components
import Card from "../../../material-kit-pro-components/Card/Card.js";
import CardBody from "../../../material-kit-pro-components/Card/CardBody.js";
import DashboardDropDownList from "../../../components/DashboardDropDownList/DashboardDropDown.component";
import LessonPreviewAccordion from "../../../components/LessonPreviewAccordion/LessonPreviewAccordion.component.jsx";
import { useState } from "react";
import CardHeader from "../../../material-kit-pro-components/Card/CardHeader.js";
import { Box } from "@material-ui/core";

const PreviousLessonsCard = ({
  multipleTracksOrCheckpointsPresent,
  previousLessonsArr,
  handleWatchVideoClick,
  userLessonTimeValidation,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card
      style={{
        minHeight: "40vh",
        minWidth: "200px",
      }}
    >
      <div>
        <CardHeader
          plain
          color="primary"
          style={{ padding: "10px 16px", marginTop: 0 }}
        >
          <Box display="flex" justifyContent="space-between">
            <h4>
              <strong>Previous Lessons</strong>
            </h4>
            {multipleTracksOrCheckpointsPresent && <DashboardDropDownList />}
          </Box>
        </CardHeader>

        <CardBody>
          {previousLessonsArr.map((item) => (
            <LessonPreviewAccordion
              key={item.id}
              useExpandedState={[expanded, setExpanded]}
              {...{
                lesson: item,
                handleWatchVideoClick,
                userLessonTimeValidation,
              }}
            />
          ))}
        </CardBody>
      </div>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  multipleTracksOrCheckpointsPresent: selectMultipleTracksOrCheckpointsPresent,
  previousLessonsArr: selectPreviousLessonsArr,
});

export default connect(mapStateToProps)(PreviousLessonsCard);
