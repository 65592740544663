export const tutorsTableFieldnames = {
  primary_tutor_groups: "primary_tutor_groups_gr[SYS]",
  secondary_tutor_groups: "secondary_tutor_groups_gr[SYS]",
  tertiary_tutor_groups: "tertiary_tutor_groups_gr[SYS]",
};

export const groupTutorFieldNames = {
  training_link: "tutor_training_link[SYS]",
  tutor_id: "tutor_id[SYS]",
};
