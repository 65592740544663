import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

import { Provider } from "react-redux";

import { persistor, store } from "./redux/store";
import App from "./App.component";
import { PersistGate } from "redux-persist/integration/react";

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={hist}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
