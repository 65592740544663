import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const DataRenderedBulletPoint = ({ classes, item }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        marginBottom: "15px",
      }}
      className={`${classes.label} ${classes.nonCheckBox} ${classes.reactMarkdown}`}
    >
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{`* ${item}`}</ReactMarkdown>
    </div>
  );
};

export default DataRenderedBulletPoint;
