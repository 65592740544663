/* eslint-disable no-unused-vars */
// Modules
import { useState, useMemo, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Redux actions and selectors

import { selectSingleLesson } from "../redux/lessons/lessons.selectors";

const useLessonUpdateMangementAdmin = (fetchCurrentLesson) => {
  const { lessonId, challengeId } = useParams();
  const history = useHistory();
  useEffect(() => {
    fetchCurrentLesson(lessonId);
  }, [lessonId]);
  // const allLessons = useSelector(selectAllLessons);
  const currentLesson = useSelector(selectSingleLesson);
  // const currentUser = useSelector(selectCurrentUser);

  const [currentIndex, setCurrentIndex] = useState(null);
  const [instructionsChecked, setInstructionsChecked] = useState(false);
  const [nextButtonActive, setNextButtonActive] = useState(false);
  const [completedChallengesArr, setCompletedChallengesArr] = useState([]);

  // Used for navigating to the correct lesson/challenge

  const lesson =
    Object.keys(currentLesson)?.length > 0 ? currentLesson : undefined;

  const challenges = useMemo(() => {
    const filteredChallenges = (lesson?.challenges || []).filter(
      (challenge) =>
        challenge?.challengeType !== "livestream" &&
        challenge?.challengeType !== "tutor_guide"
    );

    if (!filteredChallenges.length) {
      //pass
    }
    return filteredChallenges;
  }, [lesson]);

  useEffect(() => {
    if (challengeId) {
      const challengeIndex = challenges.findIndex(
        (challenge) => challenge.id === challengeId
      );
      if (challengeIndex !== -1) {
        setCurrentIndex(challengeIndex);
      }
    }
  }, [challenges]);

  const challenge = useMemo(() => {
    let newChallenge = challenges[currentIndex || 0];
    return newChallenge;
  }, [challenges, currentIndex]);

  useEffect(() => {
    if (challenge && currentIndex != null) {
      history.replace({
        pathname: `/lessons/dev/${lessonId}/${challenge?.id}`,
      });
    }
  }, [challenge]);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    setInstructionsChecked(false);
  };

  const handleNextClick = () => {
    if (currentIndex < challenges.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
    setInstructionsChecked(false);
  };

  return {
    lessonId,
    lesson,
    currentIndex,
    handlePrevClick,
    challenge,
    challenges,
    nextButtonActive,
    handleNextClick,
    setInstructionsChecked,
    completedChallengesArr,
    setCompletedChallengesArr,
    setCurrentIndex,
  };
};

export default useLessonUpdateMangementAdmin;
