// Modules
import React, { useEffect, useState } from "react";
import gifFrames from "gif-frames";

// Components
import MediaModal from "../MediaModal/MediaModal.component";
import GifCanvasAnimator from "./GifCanvasAnimator.component";

const GifCanvasAnimatorContainer = ({ src, loop, frameAnimated }) => {
  const [modal, setModal] = useState(false);
  const [gifData, setGifData] = useState(null);

  let componentMounted = true;

  useEffect(() => {
    if (frameAnimated) {
      gifFrames({
        url: src,
        frames: "all",
        outputType: "canvas",
        cumulative: true,
      })
        .then((frameData) => {
          if (componentMounted) {
            setGifData(frameData);
          }
        })
        .catch((err) => console.error(err));

      return () => (componentMounted = false);
    }
  }, []);

  return (
    <div>
      {modal && (
        <MediaModal {...{ modal, setModal }}>
          <GifCanvasAnimator
            {...{ src, gifData, modal, loop, frameAnimated }}
          />
        </MediaModal>
      )}

      <GifCanvasAnimator
        {...{ src, gifData, setModal, modal, loop, frameAnimated }}
      />
    </div>
  );
};

export default GifCanvasAnimatorContainer;
