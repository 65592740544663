// Modules
import React, { useEffect, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Redux actions and selectors
import {
  fetchLessonsAndGroupsAsyncStart,
  setCurrentUser,
} from "../../redux/user/user.actions";
import { resetLessons } from "../../redux/lessons/lessons.actions";
import { selectCurrentUser } from "../../redux/user/user.selectors";

// Firebase
import { auth, realtimeDB } from "../../utils/firebase/firebase-utils";
import { getUserRef } from "../../utils/firebase/firestore-utils";
import { setUserPresence } from "../../utils/firebase/realtime-db-utils";

// Views
import LoginPage from "../../views/LoginPage/LoginPage.view.jsx";
import Dashboard from "../../views/Dashboard/Dashboard.view.jsx";
import LessonPage from "views/LessonPage/LessonPage.view.jsx";

// Components code split for tutor only components
const SchedulePage = React.lazy(() =>
  import("../../views/SchedulePage/SchedulePage.view")
);
const TutorOverlay = React.lazy(() =>
  import("../../views/TutorOverlay/TutorOverlay.view")
);

// MUI
import { selectCurrentGroup } from "../../redux/groups/groups.selectors";

// Assets and styling

const NormalOperation = ({
  currentUser,
  setCurrentUser,
  currentGroup,
  resetLessons,
  fetchLessonsAndGroupsAsyncStart,
}) => {
  /*Authentication listener, listens for auth state and attaches a snapshot
   listener to relevent profile */
  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged((userAuth) => {
      setCurrentUser(userAuth);
      if (userAuth) {
        // Restart realtimeDB if signing back in after signing out.
        realtimeDB.goOnline();
        // Uses realtime DB
        setUserPresence(userAuth);

        const userRef = getUserRef(userAuth);
        // .onSnapshot is a realtime listener/updater with callback
        // .get to be used if simply fetching a doc
        userRef.onSnapshot(
          (snapShot) => {
            if (snapShot.exists) {
              const document = snapShot.data();
              userAuth.getIdToken().then((token) => {
                setCurrentUser({
                  id: snapShot.id,
                  admin: document.userType?.includes("admin") ? true : false,
                  token: token,
                  ...document,
                });
              });
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        realtimeDB.goOffline();
        resetLessons();
      }
    });
    return () => {
      unsubscribeFromAuth();
      resetLessons();
    };
  }, []);

  /*Load up matching lessons on firestore */
  useEffect(() => {
    fetchLessonsAndGroupsAsyncStart();
  }, [currentUser]);

  return (
    <>
      <Switch>
        <Route exact path="/">
          {
            // Render the dashboard if there is a user loaded else show the login page
            currentUser ? (
              // If the user is not an admin, show the dashboard
              // If the user is an admin and there is a currentGroup loaded show the dashboard
              // If the user is an admin and they have no currentGroup loaded show the schedule page
              !currentUser?.admin || (currentUser?.admin && currentGroup) ? (
                <Dashboard />
              ) : (
                <Suspense fallback={<div></div>}>
                  <SchedulePage />
                </Suspense>
              )
            ) : (
              <LoginPage />
            )
          }
        </Route>
        <Route path="/login" component={LoginPage}>
          <LoginPage />
        </Route>
        {currentUser && (
          <Route path="/lessons/:lessonId/:challengeId?">
            <LessonPage />
          </Route>
        )}
        <Redirect to="/" />
      </Switch>
      {currentUser?.admin && (
        <Suspense fallback={<div></div>}>
          <TutorOverlay />
        </Suspense>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentGroup: selectCurrentGroup,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  resetLessons: () => dispatch(resetLessons()),
  fetchLessonsAndGroupsAsyncStart: () =>
    dispatch(fetchLessonsAndGroupsAsyncStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NormalOperation);
