// Modules
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// Redux selectors and actions
import { selectStudentMode } from "redux/user/user.selectors";
import { selectCurrentUser } from "redux/user/user.selectors";

// Material UI
import { makeStyles } from "@material-ui/core";

// Firebase
import { s3BucketUrlForChallengeAssets } from "../../utils/firebase/firebase-utils";

// Components
import DataRenderedButton from "./components/DataRenderedButton.component.jsx";
import DataRenderedImage from "./components/DataRenderedImage.component";
import DataRenderedVideo from "./components/DataRenderedVideo.component.jsx";
import DataRenderedText from "./components/DataRenderedText.component.jsx";
import DataRenderedEmbed from "./components/DataRenderedEmbed.component.jsx";
import DataRenderedCheckBox from "./components/DataRenderedCheckBox.component.jsx";
import DataRenderedBulletPoint from "./components/DataRenderedBulletPoint.component.jsx";

// Assets and styling
import { ChallengeDataRendererStyles } from "./ChallengeDataRenderer.styles";

const useStyles = makeStyles(ChallengeDataRendererStyles);

const ChallengeDataRenderer = ({
  listArray,
  checkbox,
  challengeNumber,
  challengeId,
  setInstructionsChecked,
  setInTextHints,
  setCompletedChallengesArr,
  completedChallengesArr,
  autoPlayVideo,
  currentUser,
  studentMode,
  lessonIdProp,
}) => {
  const [checkedArr, setCheckedArr] = useState([]);
  const replacedListArray = [];
  let checkboxCount = 0;

  // For example: I1L2.1
  const lessonId = lessonIdProp;

  const lessonNameSplit = lessonId?.split(".");
  // For example: ["I1L2", "1"]

  const lessonNameWithPaddedZero =
    lessonNameSplit[0] + "." + lessonNameSplit[1].padStart(2, "0");
  // For example: "I1L2.01"

  const urlExtensionForAssets =
    s3BucketUrlForChallengeAssets +
    lessonNameWithPaddedZero.toLowerCase().split("l").join("/") +
    "/";
  // For example  "https://rockethour.s3.af-south-1.amazonaws.com/rh_lessons/i1/2.01"

  const replaceDynamicContent = (item) => {
    if (!currentUser || currentUser?.admin) {
      return item;
    }
    let dynamicContentReplacements = {
      username: currentUser.username,
      username_no_hyphen: currentUser.username.replace(/-/g, ""),
    };
    let dynamicContent = item.replace(/{{(.*?)}}/g, (match, identifier) => {
      return dynamicContentReplacements[identifier] || match;
    });

    return dynamicContent;
  };
  useEffect(() => {
    if (setInstructionsChecked && setCompletedChallengesArr) {
      if (
        checkedArr.length === checkboxCount ||
        completedChallengesArr.includes(challengeId)
      ) {
        setInstructionsChecked(true);
        setCompletedChallengesArr((prevArray) =>
          Array.from(new Set([...prevArray, challengeId]))
        );
      } else {
        setInstructionsChecked(false);
      }
    }
  }, [checkedArr]);

  useEffect(() => {
    setCheckedArr([]);
  }, [challengeId]);

  const classes = useStyles();

  for (let element of listArray) {
    let replacedElement = replaceDynamicContent(element);
    replacedListArray.push(replacedElement);
  }

  return (
    <div>
      {replacedListArray.map((item, i) => {
        if (
          item.toLowerCase().startsWith("button:") ||
          item.toLowerCase().startsWith("downloadbutton:")
        ) {
          return (
            <DataRenderedButton
              key={`${i}${challengeId}`}
              {...{ item, urlExtensionForAssets, challengeNumber }}
            />
          );
        } else if (
          item.toLowerCase().startsWith("image:") ||
          item.toLowerCase().startsWith("loopimage:") ||
          item.toLowerCase().startsWith("longimage:")
        ) {
          return (
            <DataRenderedImage
              key={`${challengeId}${i}`}
              {...{ item, challengeNumber, urlExtensionForAssets, classes }}
            />
          );
        } else if (item.toLowerCase().startsWith("video:")) {
          return (
            <DataRenderedVideo
              key={`${challengeId}${i}`}
              {...{
                item,
                challengeNumber,
                urlExtensionForAssets,
                autoPlayVideo,
              }}
            />
          );
        } else if (item.toLowerCase().startsWith("text:")) {
          return (
            <DataRenderedText
              key={`${challengeId}${i}`}
              {...{ item, classes, setInTextHints }}
            />
          );
        } else if (item.toLowerCase().startsWith("embed:")) {
          return (
            <DataRenderedEmbed
              key={`${challengeId}${i}`}
              {...{ item, classes, currentUser, studentMode }}
            />
          );
        } else {
          if (checkbox) {
            checkboxCount++;
            return (
              <DataRenderedCheckBox
                key={`${challengeId}${i}`}
                {...{
                  classes,
                  item,
                  challengeId,
                  i,
                  completedChallengesArr,
                  checkedArr,
                  setCheckedArr,
                  setInTextHints,
                }}
              />
            );
          } else {
            return (
              <DataRenderedBulletPoint
                key={`${challengeId}${i}`}
                {...{ classes, item }}
              />
            );
          }
        }
      })}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  studentMode: selectStudentMode,
});

export default connect(mapStateToProps)(ChallengeDataRenderer);
